import styled, { css } from "styled-components";
import { device } from "./Responsive.style";

const genericBtn = css`
    border-radius: 5px;
    border: none;
    white-space: nowrap;
    padding: 0.7em 2.2em;
    font-weight: 900;
    font-size: var(--font-small);
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid transparent;
    transition: background-color 0.2s;
`

export const RedPrimaryBtn = styled.button`
    ${genericBtn}
    background-color: var(--red);

    &:hover {
        background-color: var(--red-dark);
    }
    &:active {
        border: 1px solid var(--red-light);
    }

    &::before {
        content: "${(p) => p.$text}";
    }

    @media ${device.medium} {
        ${({$icon}) => $icon && `
            padding: 0.5em 1em;
            &::before {
                content: "";
                height: 24px;
                width: 24px;
                display: block;
                background: url(${$icon});
                background-size: 100%;
            }
        `}
    }
`;

export const GrayPrimaryBtn = styled.button`
    ${genericBtn}
    background-color: var(--gray);

    &:hover {
        background-color: var(--gray-dark);
    }
    &:active {
        border: 1px solid var(--gray-light);
    }

    &::before {
        content: "${(p) => p.$text}"
    }

    @media ${device.medium} {
        ${({$icon}) => $icon && `
            padding: 0.5em 1em;
            &::before {
                content: "";
                height: 24px;
                width: 24px;
                display: block;
                background: url(${$icon});
                background-size: 100%;
                filter: invert(1);
            }
        `}
    }
`;

export const GraySecondaryBtn = styled.button`
    ${genericBtn}
    background-color: var(--bg-main);
    border: 3px solid var(--gray);
    color: var(--gray);

    &:hover {
        background-color: var(--gray-light);
    }

    &::before {
        content: "${(p) => p.$text}"
    }

    @media ${device.medium} {
        ${({$icon}) => $icon && `
            padding: 0.5em 1em;
            &::before {
                content: "";
                height: 24px;
                width: 24px;
                display: block;
                background: url(${$icon});
                background-size: 100%;
                filter: invert(1);
            }
        `}
    }
`;

export const FormButton = styled.button`
    margin: 10px 0;
    padding: 10px 50px;
    border: none;
    border-radius: 5px;
    background-color: var(--accent);
    font-size: var(--font-medium);
    font-weight: 900;
    color: white;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    letter-spacing: 1px;
    transition: all 0.2s;

    &:hover {
        letter-spacing: 2px;
    }
`;


export const PrimaryButton = styled.button`
    padding: 0.3em 1.2em;
    border: 3px solid var(--accent);
    border-radius: 5px;
    background-color: var(--accent);
    font-size: var(--font-medium);
    font-weight: 900;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    box-shadow: 0 0 0 0px white inset;
    transition: all 0.2s;

    &:hover {
        box-shadow: 0 0 0 3px white inset;
        background-color: var(--accent-hover);
    }
`;

export const SecondaryButton = styled.button`
    padding: 0.3em 1.2em;
    border: 3px solid var(--accent);
    border-radius: 5px;
    background-color: white;
    font-size: var(--font-medium);
    font-weight: 900;
    color: var(--accent);
    cursor: pointer;
    letter-spacing: 1px;
    box-shadow: 0 0 0 0px white inset;
    transition: all 0.2s;

    &:hover {
        color: white;
        box-shadow: 0 0 0 3px white inset;
        background-color: var(--accent-hover);
    }
`;