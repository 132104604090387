const jobs = [
  // OLD Quiz and Attributes
    {
      name: "Software Developer",
      description: "Designs, develops, tests, and maintains software applications. Works on programming, coding, and debugging to create functional software solutions. Collaborates with cross-functional teams to ensure the delivery of high-quality software products.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 3,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 4,
        "Order & Logic": 3,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
    {
      name: "Data Analyst",
      description: "Analyzes and interprets complex data sets to provide insights and support decision-making. Utilizes statistical methods and data visualization tools to identify trends, patterns, and correlations within data. Communicates findings to stakeholders for informed business decisions.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 2,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 3,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 2,
        "Team Player & Collaborative": 3,
      },
    },
    {
      name: "IT Support Specialist",
      description: "Provides technical support to end-users, addressing hardware and software issues. Installs and configures computer systems, troubleshoots network problems, and assists in maintaining IT infrastructure. Offers timely solutions to ensure seamless technology usage.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 2,
        "Curious & Inquisitive": 3,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 4,
        "Patient & Committed": 3,
        "Problem Solving": 3,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
    {
      name: "Cybersecurity Expert",
      description: "Implements and manages security measures to protect computer systems, networks, and sensitive data from cyber threats. Conducts risk assessments, implements security protocols, and monitors for potential security breaches. Responds to and mitigates security incidents.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 2,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 4,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
    {
      name: "AI Researcher",
      description: "Engages in research and development activities focused on artificial intelligence (AI) and machine learning (ML). Explores innovative algorithms, models, and techniques to advance AI capabilities. Collaborates with interdisciplinary teams to apply AI solutions to real-world problems.",
      attributes: {
        "Communication & Empathy": 3,
        "Creative & Flexible": 4,
        "Curious & Inquisitive": 4,
        "Detailed & Technically Minded": 4,
        "Efficiency & Improvement": 3,
        "Order & Logic": 3,
        "Patient & Committed": 3,
        "Problem Solving": 4,
        "Resilient & Positive": 3,
        "Team Player & Collaborative": 3,
      },
    },
  ]

  const newJobs = [
    // New Quiz and Attributes
    {
      name: "Business Analyst",
      description: "A Business Analyst evaluates business processes, identifies inefficiencies, and works with stakeholders to design solutions that meet organisational goals. They act as a bridge between technical teams and business users, gathering requirements, defining objectives, and ensuring successful implementation of process improvements or system changes.",
      attributes: {
        "Problem Solving": 20,
        "Communication Skills": 15,
        "Attention to Detail": 15,
        "Critical Thinking": 10,
        "Curiosity": 10,
        "Adaptability & Resilience": 10,
        "Team Collaboration": 10,
        "Growth Mindset": 5,
        "Time Management": 5,
        "Empathy": 0
      },
    },
    {
      name: "Data Analyst",
      description: "A Data Analyst collects, cleans, and interprets large datasets to provide actionable insights that drive business decisions. They use statistical tools and software to identify trends, generate reports, and help organisations optimise their strategies and performance.",
      attributes: {
        "Problem Solving": 20,
        "Communication Skills": 10,
        "Attention to Detail": 15,
        "Critical Thinking": 20,
        "Curiosity": 15,
        "Adaptability & Resilience": 5,
        "Team Collaboration": 5,
        "Growth Mindset": 10,
        "Time Management": 0,
        "Empathy": 0
      },
    },
    {
      name: "Project Manager",
      description: "A Project Manager is responsible for overseeing the planning, execution, and successful completion of projects, ensuring they are delivered on time, within scope, and on budget. They manage resources, coordinate teams, communicate with stakeholders, and mitigate risks to keep projects on track.",
      attributes: {
        "Problem Solving": 20,
        "Communication Skills": 20,
        "Attention to Detail": 10,
        "Critical Thinking": 0,
        "Curiosity": 0,
        "Adaptability & Resilience": 10,
        "Team Collaboration": 15,
        "Growth Mindset": 5,
        "Time Management": 15,
        "Empathy": 5
      },
    },
    {
      name: "Service Desk Analyst",
      description: "A Service Desk Analyst provides front-line IT support, troubleshooting technical issues related to hardware, software, and network systems. They maintain service efficiency by resolving incidents, escalating complex issues to appropriate teams, and ensuring users have a smooth and productive IT experience.",
      attributes: {
        "Problem Solving": 20,
        "Communication Skills": 20,
        "Attention to Detail": 15,
        "Critical Thinking": 0,
        "Curiosity": 5 ,
        "Adaptability & Resilience": 10,
        "Team Collaboration": 15,
        "Growth Mindset": 5,
        "Time Management": 5,
        "Empathy": 5
      },
    },
    {
      name: "Software Developer",
      description: "A Software Developer assists in writing, testing, and debugging code to build and improve software applications under the guidance of senior developers. They collaborate with teams to ensure software meets requirements, stays updated with technology trends, and adheres to best practices in coding and design.",
      attributes: {
        "Problem Solving": 25,
        "Communication Skills": 10,
        "Attention to Detail": 15,
        "Critical Thinking": 0,
        "Curiosity": 20,
        "Adaptability & Resilience": 10,
        "Team Collaboration": 10,
        "Growth Mindset": 10,
        "Time Management": 0,
        "Empathy": 0
      },
    },
    {
      name: "Cyber Security Analyst",
      description: "A Cyber Security Analyst helps protect an organisation’s data and IT infrastructure by monitoring systems for threats, analysing vulnerabilities, and responding to security incidents. They assist in implementing security measures, conducting risk assessments, and staying current with emerging cyber threats and defense technologies.",
      attributes: {
        "Problem Solving": 25,
        "Communication Skills": 12,
        "Attention to Detail": 20,
        "Critical Thinking": 20,
        "Curiosity": 10,
        "Adaptability & Resilience": 5,
        "Team Collaboration": 3,
        "Growth Mindset": 5,
        "Time Management": 0,
        "Empathy": 0
      },
    },
    {
      name: "Network Engineer",
      description: "A Network Engineer helps design, configure, and maintain the organisation’s network infrastructure, ensuring reliable and secure communication across all systems. They assist in troubleshooting network issues, optimising performance, and implementing new technologies to support the growing needs of the business.",
      attributes: {
        "Problem Solving": 25,
        "Communication Skills": 5,
        "Attention to Detail": 20,
        "Critical Thinking": 0,
        "Curiosity": 15,
        "Adaptability & Resilience": 10,
        "Team Collaboration": 15,
        "Growth Mindset": 10,
        "Time Management": 0,
        "Empathy": 0
      },
    },
  ];
  

export const predictedJobs = (finalAttributes, avgScores) => {
    const jobScores = [];

    let jobList = finalAttributes.includes("Order & Logic") ? jobs : newJobs

    for (const job of jobList) {
        let totalScore = 0;
        
        for (let i = 0; i < finalAttributes.length; i++) {
            const responseScore = avgScores[i] || 0;
            const attributeWeight = job.attributes[finalAttributes[i]] || 0;
            totalScore += responseScore * attributeWeight;
        }
    
        jobScores.push({ job: job.name, score: totalScore, desc: job.description });
    }
    
    jobScores.sort((a, b) => b.score - a.score);
  
    return jobScores.slice(0,3);
}