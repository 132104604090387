import longCNLogo from "../assets/images/longWhiteCNLogo.png";
import styled from "styled-components";
import digiDnaLogo from "../assets/images/digiDNALogo.png";
import e2eLogo from "../assets/images/e2eIntegrationLogo.png";
import { device } from "../styles/Responsive.style.js";
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <FooterWrapper>
            <LeftSection>
                <div>
                    <h2><Link to="/"><img src={digiDnaLogo} alt="Logo"/><b><span>digi</span>DNA</b></Link>powered by:</h2>
                    <a href="https://wearecodenation.com/" target="_blank" rel="noreferrer"><img className="cnLogo" src={longCNLogo} alt="Code Ntion Logo"/></a>
                    <a className="e2eLink" href="https://e2e-integration.co.uk/" target="_blank" rel="noreferrer"><img src={e2eLogo} alt="E2E Integration"/></a>
                </div>
                <a href="mailto:hello@digidna.ai"><p>hello@digidna.ai</p></a>
            </LeftSection>
            <RightSection>
                <p>digiDNA © 2025 | <Link to="/privacyNotice">Privacy Notice</Link> | Terms of Service</p>
                <p>digiDNA is a registered trademark, all rights reserved.</p>
            </RightSection>
        </FooterWrapper>
    )
}

export default Footer;

const FooterWrapper = styled.footer`
    background-color: var(--dark-bg);
    display: flex;
    justify-content: space-between;
    padding: 50px;
    margin-top: 25px;
    
    & * {
        color: var(--text-light);
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 20px;
    }

    a {
        text-decoration: none;
        display: block;
    }

    h2 {
        font-weight: 400;
        margin: 6px 0;
        color: var(--text-light);
        display: flex;
        align-items: center;
        font-size: 20px;
        flex-basis: 100%;
    }

    b {
        text-decoration: none;
        font-size: 34px;
        color: var(--text-light);
        margin-right: 5px;
    }

    span {
        color: var(--text-light);
        font-weight: 400;
    }

    img {
        height: 30px;
        margin-right: 10px;
        margin-left: 5px;
    }

    p {
        flex-basis: 100%;
        margin: 6px 0;
    }

    .e2eLink {
        margin-left: 5px;

        img {
            height: 70px;
        }
    }

    & > a {
        font-size: var(--font-small);
    }
    
    @media ${device.medium} {
        flex-direction: column;
        align-items: flex-start;

        p {
            flex-basis: auto;
            margin: 15px 0 0 0;
        }
    }

    @media ${device.small} {
        h2 {
            flex-direction: column;
            align-items: flex-start;
            font-size: 20px;
        }

        .cnLogo {
            height: auto;
            width: 100%;
            margin: 0px;
            max-width: 250px;
        }
    }
`;

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    p {
        margin: 6px 0;
        font-size: var(--font-small)
    }

    a {
        text-decoration: none;
    }

    @media ${device.small} {
        margin-top: 25px;
    }
`;