import React from "react";
import styled from "styled-components";
import { device } from "../styles/Responsive.style.js";


const QuestionCard = ({ questionNum, totalQuestions,  questionInfo, savedAnswerId, changeAns, isDisabled }) => {

    return (
        <CardWrapper>
            <h4>Question {questionNum}/{totalQuestions}</h4>
            <p>{questionInfo.question}</p>

            <AnswerSection className={questionInfo.answers.length === 3 ? "threeAns" : ""}>
                {questionInfo.answers.map((answer) => {
                    return (
                        <React.Fragment key={answer._id}>
                            <AnswerInput type="radio" id={answer._id} onChange={() => changeAns(questionInfo._id, answer._id, answer.points)} defaultChecked={savedAnswerId === answer._id ? true : false} name={questionInfo._id} required disabled={isDisabled}></AnswerInput>
                            <AnswerLabel htmlFor={answer._id} isDisabled={isDisabled}>{answer.answer}</AnswerLabel>
                        </React.Fragment>
                    )
                })}
            </AnswerSection>

        </CardWrapper>
    )
}

export default QuestionCard;

const CardWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: var(--primary-gray);
    margin: 25px 0 0 25px;
    border-radius: 15px;
    border: 5px solid var(--primary-gray);
    box-shadow: 0 0 0 1px var(--secondary-gray) inset, 0 0 0 var(--secondary-gray);
    display: flex;
    max-width: 1500px;

    h4 {
        margin: 0;
        font-size: calc(var(--font-small) * 0.89);
        font-weight: 400;
        color: var(--gray);
    }

    p {
        font-size: var(--font-small);
        margin: 8px 0;
        font-weight: 900;
    }

    @media ${device.small} {
        margin: 25px 0;
        padding: 10px;

        h4 {
            font-size: calc(var(--font-small) * 0.75);
        }
    }
`;

const AnswerSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 12px 20px;
`;

const AnswerLabel = styled.label`
    position: relative;
    font-size: var(--font-small);
    background-color: var(--secondary-gray);
    width: calc(50% - 10px);
    box-sizing: border-box;
    padding: 16px 20px 16px 50px;
    border-radius: 10px;
    box-shadow: 2px 4px 7px 0 var(--gray-light);
    display: flex;
    align-items: center;
    cursor: ${(props) => props.isDisabled ? "cursor" : "pointer"};

    &::before {
        content: '';
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        height: 14px; 
        width: 14px;
        border-radius: 100%;
        border: 2px solid var(--text-main);
    }

    &::after {
        content: '';
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%) scale(0);
        height: 10px; 
        width: 10px;
        border-radius: 100%;
        background-color: white;
        transition: all 0.2s;
    }

    .threeAns & {
        width: calc(33% - 13px);
    }

    @media (max-width: 1000px) {
        .threeAns & {
            width: calc(100%);
        }
    }

    @media ${device.medium} {
        font-size: calc(var(--font-small) * 0.89);
        padding: 10px 10px 10px 50px;
    }

    @media ${device.small} {
        width: 100%;
        padding: 10px 10px 10px 50px;
    }
`;

const AnswerInput = styled.input`
    display: none;

    &:checked + label {
        background-color: var(--accent);
        color: white;
    }

    &:checked + label::before {
        border: 2px solid white;
    }

    &:checked + label::after {
        transform: translateY(-50%) scale(1);
    }
`;