import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();

const PrivateRoute = ({ children, state, roles, skipVerification, skipTokenCheck }) => {
    const loggedIn = cookies.get('token');
    
    if(!loggedIn && !skipTokenCheck && state !== "loggedIn") {
        return <Navigate to="/" />
    }
    if((!loggedIn && skipTokenCheck) || (state === "loggedIn" && !loggedIn)){
        return children
    }

    const decoded = jwtDecode(loggedIn);

    if(decoded.exp < new Date().getTime() / 1000) {
        cookies.remove('token');
        return <Navigate to="/login" />
    }
    
    if(decoded.name === undefined && !decoded.isVerified){ 
        return <Navigate to="/register" />
    }

    if(!skipVerification && (decoded.isVerified === undefined || !decoded.isVerified)) {
        return <Navigate to="/verifyEmail" />
    }

    if(skipVerification && decoded.isVerified){
        return <Navigate to="/questionnaires" />
    }

    if(state === "loggedIn" && loggedIn) {
        return <Navigate to="/questionnaires" />
    }

    if(roles && (!decoded.role || roles.indexOf(decoded.role) === -1)){
        return <Navigate to="/questionnaires" />
    }

    return children
}

export default PrivateRoute;