import { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userName, setUserName] = useState("Account");
    const [blurryBg, setBlurryBg] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState("system");

    return (
        <UserContext.Provider value={{ userName, setUserName, blurryBg, setBlurryBg, showErrorModal, setShowErrorModal, showContactForm, setShowContactForm, isDarkMode, setIsDarkMode }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => {
    return useContext(UserContext);
}