import { useState, useEffect } from "react";

function usePrefersDarkMode() {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

        setIsDarkMode(mediaQuery.matches);
        if(mediaQuery.matches) {
            document.documentElement.classList.add("dark")
            document.documentElement.classList.remove("light")
        } else {
            document.documentElement.classList.add("light")
            document.documentElement.classList.remove("dark")
        }
        const handleChange = (e) => {
            setIsDarkMode(e.matches);
        };

        mediaQuery.addEventListener("change", handleChange);
        return () => {
            mediaQuery.removeEventListener("change", handleChange);
        };
    }, []);

    return isDarkMode ? "dark" : "light";
}

export default usePrefersDarkMode;