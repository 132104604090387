import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import RegisterUser from "../components/RegisterUser.js";
import RegisterAdmin from "../components/RegisterAdmin.js";
import PageTransition from "../components/PageTransition.js";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { device } from "../styles/Responsive.style.js";

const cookies = new Cookies();

const Register = () => {
    const [role, setRole] = useState(null);

    const navigate = useNavigate()
    useEffect(() => {
        const loggedIn = cookies.get('token');

        if(!loggedIn) {
            return navigate("/login")
        } else if(jwtDecode(loggedIn).name) {
            return navigate("/questionnaires")
        }
        setRole(jwtDecode(loggedIn).role);
    }, [])

    return (
        <SectionBlock>
            
            {role === "User" ? (
                <RegisterUser />
            ) : role === "Admin" ? (
                <RegisterAdmin />
            ) : (
                <h1>Not Logged In</h1>
            )}

            <PageTransition isLoading={false} />
        </SectionBlock>
    )
}

export default Register;

const SectionBlock = styled.section`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.small} {
        align-items: flex-start;
    }
`;