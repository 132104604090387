import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { FormInput, Checkbox } from "../styles/Form.styles.js";
import { FormButton } from "../styles/Button.styles.js";
import styled from "styled-components";
import { registerAdmin } from "../utils/index.js";
import { device } from "../styles/Responsive.style.js";

const cookies = new Cookies();

const RegisterAdmin = () => {
    const [errMsg, setErrMsg] = useState("");

    const passwordRef = useRef();
    const confirmPassRef = useRef();
    const nameRef = useRef();
    const companyRef = useRef();
    const termsRef = useRef();

    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();

        if(passwordRef.current.value !== confirmPassRef.current.value){
            setErrMsg("Passwords must match")
            return;
        }

        if(!termsRef.current.checked){
            setErrMsg("To Sign Up, accept Terms & Conditions");
            return;
        }
        
        let data = await registerAdmin(nameRef.current.value, passwordRef.current.value, companyRef.current.value, new Date())
        
        if(data.registered) {
            await cookies.set("token", data.newToken)
            navigate("/verifyEmail")
            return;
        } else {
            setErrMsg(data.message)
            return;
        }
    }

    return (
        <RegisterForm onSubmit={handleRegister}>
            <h1>Welcome!</h1>
            <p className="welcomeMsg">As this is your first time logging in, you will need to finish registering.</p>
            <FormInput>      
                <input type="password" placeholder=" " ref={passwordRef} minLength="8" maxLength="30" required />
                <label>Password (8 characters minimum)</label>
            </FormInput>
            <FormInput>   
                <input type="password" placeholder=" " ref={confirmPassRef} minLength="8" maxLength="30" required />
                <label>Confirm Password</label>
            </FormInput>
            <FormInput>   
                <input type="text" placeholder=" " ref={nameRef} required />
                <label>Full Name</label>
            </FormInput>
            <FormInput>   
                <input type="text" placeholder=" " ref={companyRef} required />
                <label>Company Name</label>
            </FormInput>
            <Checkbox htmlFor="TsandCs">
                <input ref={termsRef} type="checkbox" id="TsandCs" />
                I consent to my information being used for the purpose of this attribute questionnaire
                <div className="customCheckbox"></div>
            </Checkbox>
            <ErrorMessage>{errMsg}</ErrorMessage>
            <FormButton type="submit">Register</FormButton>
        </RegisterForm>
    )
}

export default RegisterAdmin;

const RegisterForm = styled.form`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: var(--bg-main);
    padding: 50px;
    border-radius: 5px;

    h1 {
        margin: 0;
        font-size: 48px;
    }

    .welcomeMsg {
        font-size: 18px;
        width: 300px;
        margin: 0 0 20px;
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }

    & > div {
        margin: 0;
    }

    .tandc-closed {
        overflow: hidden;
        white-space: nowrap;
    }

    #tandc {
        background-color: var(--secondary-gray);
        padding: 10px;
        border-radius: 5px;
        border: 1px solid black;
        text-overflow: ellipsis;
        width: 350px;
        box-sizing: border-box;
        margin-bottom: 0;
    }

    #readMoreBtn {
        margin-top: 5px;
        color: var(--blue);
        cursor: pointer;
    }

    @media ${device.small} {
        width: 100%;
        padding: 20px;
        margin: 50px 5px 0;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin: 0;
    height: 19px;
    margin-top: 10px;
`;