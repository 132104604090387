import styled from "styled-components";
import { device } from "../styles/Responsive.style";

import bcn from "../assets/images/company_logos/bcn.svg";
import capital_one from "../assets/images/company_logos/capital-one.png";
import cambridge_regional_college from "../assets/images/company_logos/cambridge-regional-college.png";
import co_op_bank from "../assets/images/company_logos/co-op-bank.png";
import codenation from "../assets/images/company_logos/codenation.png";
import emis_health from "../assets/images/company_logos/emis-health.png";
import n_brown_group from "../assets/images/company_logos/n-brown-group.png";
import northern_reach from "../assets/images/company_logos/northern-reach.png";
import reed_talent_solutions from "../assets/images/company_logos/reed-talent-solutions.png";
import resulting_it from "../assets/images/company_logos/resulting-it.webp";
import robiquity from "../assets/images/company_logos/robiquity.png";
import sg_digital from "../assets/images/company_logos/sg-digital.png";
import sky from "../assets/images/company_logos/sky.png";
import vivio_developments from "../assets/images/company_logos/vivio-develpoments.jpg";
import vivio from "../assets/images/company_logos/vivio.jpg";

const Carousel = () => {

    return (
        <CarouselWrapper>
            <ImageGroup>
                <img src={bcn} alt="BCN logo" />
                <img src={capital_one} alt="Capital One logo" />
                <img src={cambridge_regional_college} alt="Cambridge Regional College logo" />
                <img src={co_op_bank} alt="Co-op Bank logo" />
                <img src={codenation} alt="Codenation logo" />
                <img src={emis_health} alt="Emis Health logo" />
                <img src={n_brown_group} alt="N Brown Group logo" />
                <img src={northern_reach} alt="Northern Reach logo" />
                <img src={reed_talent_solutions} alt="Reed Talent Solutions logo" />
                <img src={resulting_it} alt="Resulting IT logo" />
                <img src={robiquity} alt="Robiquity logo" />
                <img src={sg_digital} alt="SG Digital logo" />
                <img src={sky} alt="Sky logo" />
                <img src={vivio_developments} alt="Vivio Developments logo" />
                <img src={vivio} alt="Vivio logo" />
            </ImageGroup>
            <ImageGroup  aria-hidden>
                <img src={bcn} alt="BCN logo" />
                <img src={capital_one} alt="Capital One logo" />
                <img src={cambridge_regional_college} alt="Cambridge Regional College logo" />
                <img src={co_op_bank} alt="Co-op Bank logo" />
                <img src={codenation} alt="Codenation logo" />
                <img src={emis_health} alt="Emis Health logo" />
                <img src={n_brown_group} alt="N Brown Group logo" />
                <img src={northern_reach} alt="Northern Reach logo" />
                <img src={reed_talent_solutions} alt="Reed Talent Solutions logo" />
                <img src={resulting_it} alt="Resulting IT logo" />
                <img src={robiquity} alt="Robiquity logo" />
                <img src={sg_digital} alt="SG Digital logo" />
                <img src={sky} alt="Sky logo" />
                <img src={vivio_developments} alt="Vivio Developments logo" />
                <img src={vivio} alt="Vivio logo" />
            </ImageGroup>
        </CarouselWrapper>
    )
}

export default Carousel;

const CarouselWrapper = styled.div`
    padding: 20px 0;
    overflow: hidden;
    display: flex;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    position: relative;


    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, var(--bg-main), rgba(255,255,255,0) 10%, rgba(255,255,255,0) 90%, var(--bg-main));
        z-index: 1;
    }
`;

const ImageGroup = styled.div`
    display: flex;
    gap: 40px;
    padding-right: 20px;
    align-items: center;
    will-change: transform;
    animation: scrolling 20s linear infinite;
  
    img {
        width: 150px;
    }

    @keyframes scrolling {
        0% {
        transform: translateX(0);
        }
        100% {
        transform: translateX(-100%);
        }
    }

    @media ${device.small} {
        img {
            width: 90px;
        }
        gap: 20px;
    }
`;