import { useEffect, useState } from "react";
import styled from "styled-components";
import smallArrow from "../../assets/icons/small-arrow.png";
import { useAdmin } from "../../context/AdminContext";

const Pagination = () => {

    const { currentPage, setCurrentPage, displayUsers, usersPerPage, selectedGroup } = useAdmin();
    const [visibleBtns, setVisibleBtns] = useState([]);

    const changePage = () => {
        let displayed_buttons = []
        let numberOfPages = Math.ceil(displayUsers.length / usersPerPage)
        if (numberOfPages > 7) {
            if (currentPage > 4) {
                if (numberOfPages - currentPage < 4) {
                    displayed_buttons.unshift(1, "...")
                    for (let i = numberOfPages - 4; i <= numberOfPages; i++) {
                        displayed_buttons.push(i)
                    }
                } else {
                    displayed_buttons = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", numberOfPages]
                }
            }
            if (currentPage <= 4) {
                displayed_buttons = [1, 2, 3, 4, 5, "...", numberOfPages]
            }
        } else {
            for (let i = 1; i <= numberOfPages; i++) {
                displayed_buttons.push(i)
            }
        }
        setVisibleBtns([...displayed_buttons])
    }

    useEffect(() => {
        changePage();
    }, [currentPage, selectedGroup])

    return (
        <PaginationWrapper>
            <button className="moveBtn left" onClick={() => currentPage !== 1 ? setCurrentPage(currentPage - 1) : null}><img src={smallArrow} className="black-icon" alt="arrow pointing left" /></button>
            {visibleBtns.map((btn, index) => (
                btn === "..." ? <p key={index}>...</p> : <button key={index} onClick={() => setCurrentPage(btn)} className={currentPage === btn ? "current-page" : ""}>{btn}</button>
            ))}
            <button className="moveBtn right" onClick={() => currentPage !== Math.ceil(displayUsers.length / usersPerPage) ? setCurrentPage(currentPage + 1) : null}><img src={smallArrow} className="black-icon" alt="arrow pointing right" /></button>
        </PaginationWrapper>
    )
}

export default Pagination;


const PaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    p {
        font-size: var(--font-small);
        margin: 0;
        width: 30px;
        text-align: center;
    }

    button {
        height: 30px;
        width: 30px;
        font-size: var(--font-small);
        cursor: pointer;
        border-radius: 5px;
        border: none;
        background-color: transparent;
        transition: background-color 0.2s;

        &:not(.current-page):not(.moveBtn):hover {
            background-color: var(--accent-hover);
        }

        &.moveBtn:hover {
            background-color: var(--bg-main-light);
        }
    }

    .current-page {
        background-color: var(--accent);
        color: white;
        font-weight: 900;
    }

    .moveBtn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .moveBtn img {
        height: 10px;
    }

    .left {
        transform: rotate(90deg);
    }
    .right {
        transform: rotate(-90deg);
    }
`;