import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Checkbox, Dropdown, FormInput } from "../styles/Form.styles.js";
import { FormButton } from "../styles/Button.styles.js";
import styled from "styled-components";
import { getGroupRegister, registerUser } from "../utils/index.js";

import { device } from "../styles/Responsive.style.js";

const cookies = new Cookies();

const RegisterUser = () => {
    const [errMsg, setErrMsg] = useState("");
    const [requiredData, setRequiredData] = useState([]);
    const [skillsToCheck, setSkillsToCheck] = useState([]);
    const [selectedSkills,setSelectedSkills] = useState([]);
    const [specialAccount, setSpecialAccount] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [selectGender, setSelectGender] = useState("");
    const [selectAgeRange , setSelectAgeRange] = useState("");

    const passwordRef = useRef();
    const confirmPassRef = useRef();

    const adultsNameRef = useRef();
    const adultsEmailRef = useRef();
    const forenameRef = useRef();
    const surnameRef = useRef();

    const ageRangeRef = useRef();
    const educationRef = useRef();
    const prevTechSkillRef = useRef();
    const statusRef = useRef();
    const regionRef = useRef();
    const regionNWRef = useRef();
    const neurodiversityRef = useRef();
    const ethnicityRef = useRef();
    const genderRef = useRef();
    const genderOtherRef = useRef();
    const aspirationRef = useRef();
    const termsRef = useRef();
    const marketingRef = useRef();

    const navigate = useNavigate();

    const fetchGroup = async () => {
        const res = await getGroupRegister(null)

        if(res.message === "Group Found"){
            setRequiredData(res.captured_data)
            setSkillsToCheck(res.skills)
            setCompanyName(res.company_name)
            setSpecialAccount(res.specialAccount)
        } else {
            setErrMsg("Something went wrong, please try again later.")
        }
    }

    useEffect(() => {
        fetchGroup()
    }, [])

    const handleToggleSkill = (skill) => {
        if(selectedSkills.includes(skill)){
            setSelectedSkills(selectedSkills.filter((s) => s !== skill))
        } else {
            setSelectedSkills(prev => [...prev, skill])
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();

        if(passwordRef.current.value !== confirmPassRef.current.value){
            setErrMsg("Passwords must match")
            return;
        }

        if(!termsRef.current.checked){
            setErrMsg("To Sign Up, accept Terms & Conditions");
            return;
        }

        const extra_data = {}
        
        const extraInfoRefs = {
            ageRange: requiredData.includes("ageRange") && ageRangeRef.current.value,
            education: requiredData.includes("education") && educationRef.current.value,
            prevTechSkill: requiredData.includes("prevTechSkill") && prevTechSkillRef.current.value,
            status: requiredData.includes("status") && statusRef.current.value,
            region: requiredData.includes("region") && regionRef.current.value,
            aspiration: requiredData.includes("aspiration") && aspirationRef.current.value,
            regionNW: requiredData.includes("regionNW") && regionNWRef.current.value,
            neurodiversity: requiredData.includes("neurodiversity") && neurodiversityRef.current.value,
            ethnicity: requiredData.includes("ethnicity") && ethnicityRef.current.value,
            gender: requiredData.includes("gender") && genderRef.current.value,
        }

        requiredData.forEach((data) => {
            if(data === "gender" && selectGender === "Other"){
                extra_data[data] = genderOtherRef.current.value
            } else if (data === "ageRange" && selectAgeRange === "14 - 18") {
                extra_data[data] = extraInfoRefs[data]
                extra_data["adultsName"] = adultsNameRef.current.value
                extra_data["adultsEmail"] = adultsEmailRef.current.value
            } else {
                extra_data[data] = extraInfoRefs[data]
            }
        })

        extra_data.skills = selectedSkills

        let data = await registerUser(`${forenameRef.current.value.trim()}, ${surnameRef.current.value.trim()}`, passwordRef.current.value, extra_data, new Date(), (marketingRef.current.checked) ? new Date() : null );

        if(data.registered) {
            await cookies.set("token", data.newToken)
            navigate("/verifyEmail");
            return;
        } else {
            setErrMsg(data.message);
            return;
        }
    }

    return (
        <RegisterForm onSubmit={handleRegister}>
            <h1>Welcome!</h1>
            {specialAccount === "" && <h2>{companyName} invites you to digiDNA to discover your future.</h2>}
            <p className="welcomeMsg">As this is your first time logging in, you will need to finish registering.</p>
            {specialAccount === "cyberLocal" && (
                <CyberLocalText>
                    <p><b>Get Ready to Unlock Your Tech Potential with digiDNA!</b></p>
                    <p>We’re excited to share that Code Nation has received funding from the Department for Science, Innovation & Technology, through Innovate UK to supercharge our tech attributes tool, digiDNA, and we want <b>you</b> to be part of this trailblazing journey!</p>
                    <p><b>What’s in it for you?</b> By completing digiDNA, you’ll uncover if your unique strengths match exciting cyber security careers right here in the North West.</p>
                    <p>Quick Facts:</p>
                    <ul>
                        <li><b>Time to Complete:</b> About 30 minutes (you can pause and return anytime), however the sooner you complete the more opportunities you may be able to unlock!</li>
                        <li><b>Deadline:</b> All questionnaires must be completed by 31st March 2025.</li>
                    </ul>
                    <p><b>No Tech Experience? No Problem!</b> The questions are designed for beginners, just be yourself and let your hidden talents shine.</p>
                    <p><b>Why Should You Take Part?</b> When you finish, you’ll unlock a personalised dashboard featuring:</p>
                    <ul>
                        <li><b>Personal Insights:</b> how your unique attributes align with cyber security roles, which you can share with hiring employers, job coaches or careers advisers</li>
                        <li><b>Tailored Career Pathway:</b> bespoke information, including access to local training courses and job opportunities</li>
                        <li><b>Exclusive Opportunities:</b> If your results show you’re a great fit for cyber security, you’ll be invited to a <b>FREE Inspire Cyber Security Session</b> and <b>one-to-one career advice</b> to kickstart your career!</li>
                    </ul>
                </CyberLocalText>
            )}
            <FormInput className="textType">
                <input type="text" placeholder=" " ref={forenameRef} required />
                <label>Forename</label>
            </FormInput>
            <FormInput className="textType">
                <input type="text" placeholder=" " ref={surnameRef} required />
                <label>Surname</label>
            </FormInput>
            <FormInput className="textType">      
                <input type="password" placeholder=" " ref={passwordRef} minLength="8" maxLength="30" required />
                <label>Password (8 characters minimum)</label>
            </FormInput>
            <FormInput className="textType">   
                <input type="password" placeholder=" " ref={confirmPassRef} minLength="8" maxLength="30" required />
                <label>Confirm Password</label>
            </FormInput>

            {requiredData.includes("ageRange") && (
                <Dropdown>
                    <label>Age Range</label>
                        <select ref={ageRangeRef} required onChange={(e) => setSelectAgeRange(e.target.value)}>
                        <option value="">Select an Option</option>
                        <option value="14 - 18">14 - 18</option> 
                        <option value="19 - 24">19 - 24</option>  
                        <option value="25 - 34">25 - 34</option>  
                        <option value="35 - 44">35 - 44</option>  
                        <option value="45 - 54">45 - 54</option>  
                        <option value="55 - 64">55 - 64</option>
                        <option value="65 and Over">65 and Over</option> 
                        <option value="Prefer not to say">Prefer not to say</option>      
                    </select>
                </Dropdown>
            )}
            {selectAgeRange === "14 - 18" && (
                <>
                    <p>As you are 18 years old or under, you are required to provide a consenting adults contact information</p>
                    <FormInput className="textType">
                        <input type="text" placeholder=" " ref={adultsNameRef} required />
                        <label>Adults Name</label>
                    </FormInput>
                    <FormInput className="textType">
                        <input type="email" placeholder=" " ref={adultsEmailRef} required />
                        <label>Adults Email</label>
                    </FormInput>
                </>
            )}
            {requiredData.includes("gender") && (
                <Dropdown>
                    <label>Gender Identity</label>
                    <select ref={genderRef} required onChange={(e) => setSelectGender(e.target.value)}>
                        <option value="">Select an Option</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Nonbinary">Nonbinary</option>
                        <option value="Other">Other</option>
                        <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                </Dropdown>
            )}
            {selectGender === "Other" && (
                <FormInput className="textType">
                    <input type="text" placeholder=" " ref={genderOtherRef} required />
                    <label>Please Specify</label>
                </FormInput>
            )}
            {requiredData.includes("education") && (
                <Dropdown>
                    <label>Highest Form of Education</label>
                    <select ref={educationRef} required>
                        <option value="">Select an Option</option>
                        <option value="Level 1">Level 1 (GCSE Grades 1, 2, 3)</option> 
                        <option value="Level 2">Level 2 (GCSE Grades 4 - 9)</option>  
                        <option value="Level 3">Level 3 (AS and A levels)</option>  
                        <option value="Level 4">Level 4 (BTEC Professional diplomas)</option>  
                        <option value="Level 5">Level 5 (Foundation degrees)</option>  
                        <option value="Level 6">Level 6 (Bachelor's degrees)</option>
                        <option value="Level 7">Level 7 (Master's degrees)</option>    
                        <option value="Level 8">Level 8 (Doctoral degrees)</option>  
                        <option value="None">No qualifications</option>    
                    </select>
                </Dropdown>
            )}
            {requiredData.includes("prevTechSkill") && (
                <Dropdown>
                    <label>Previous skills in tech</label>
                    <select ref={prevTechSkillRef} required>
                        <option value="">Select an Option</option>
                        <option value="Never">Never been in tech</option>
                        <option value="Little">A little experience in tech</option> 
                        <option value="Experienced">Experienced in tech</option> 
                    </select>
                </Dropdown>
            )}
            {requiredData.includes("status") && (
                <Dropdown>
                    <label>Employment Status</label>
                    <select ref={statusRef} required>
                        <option value="">Select an Option</option>
                        <option value="Unemployed">Unemployed</option> 
                        <option value="Employed Full-Time">Employed Full-Time</option>
                        <option value="Employed Part-Time">Employed Part-Time</option>
                        <option value="Self-Employed">Self-Employed</option>
                        <option value="Full-Time Education">Full-Time Education</option>
                    </select>
                </Dropdown>
            )}
            {requiredData.includes("region") && (
                <Dropdown>
                    <label>Region</label>
                    <select ref={regionRef} required>
                        <option value="">Select an Option</option>
                        <option value="Bedfordshire">Bedfordshire</option> 
                        <option value="Buckinghamshire">Buckinghamshire</option>
                        <option value="Cambridgeshire">Cambridgeshire</option>
                        <option value="Cheshire">Cheshire</option> 
                        <option value="Cleveland">Cleveland</option>               
                        <option value="Cornwall">Cornwall</option>      
                        <option value="Cumbria">Cumbria</option>      
                        <option value="Derbyshire">Derbyshire</option>      
                        <option value="Devon">Devon</option>      
                        <option value="Dorset">Dorset</option>      
                        <option value="Durham">Durham</option>      
                        <option value="East Sussex">East Sussex</option>

                        <option value="Essex">Essex</option>
                        <option value="Gloucestershire">Gloucestershire</option>
                        <option value="Greater London">Greater London</option>
                        <option value="Greater Manchester">Greater Manchester</option>
                        <option value="Hampshire">Hampshire</option>
                        <option value="Hertfordshire">Hertfordshire</option>
                        <option value="Kent">Kent</option>
                        <option value="Lancashire">Lancashire</option>
                        <option value="Leicestershire">Leicestershire</option>
                        <option value="Lincolnshire">Lincolnshire</option>
                        <option value="Merseyside">Merseyside</option>
                        <option value="Norfolk">Norfolk</option>
                        <option value="North Yorkshire">North Yorkshire</option>
                        <option value="Northamptonshire">Northamptonshire</option>
                        <option value="Northumberland">Northumberland</option>
                        <option value="Nottinghamshire">Nottinghamshire</option>
                        <option value="Oxfordshire">Oxfordshire</option>
                        <option value="Shropshire">Shropshire</option>
                        <option value="Somerset">Somerset</option>
                        <option value="South Yorkshire">South Yorkshire</option>
                        <option value="Staffordshire">Staffordshire</option>
                        <option value="Suffolk">Suffolk</option>
                        <option value="Surrey">Surrey</option>
                        <option value="Tyne and Wear">Tyne and Wear</option>
                        <option value="Warwickshire">Warwickshire</option>
                        <option value="West Berkshire">West Berkshire</option>
                        <option value="West Midlands">West Midlands</option>
                        <option value="West Sussex">West Sussex</option>
                        <option value="West Yorkshire">West Yorkshire</option>
                        <option value="Wiltshire">Wiltshire</option>
                        <option value="Worcestershire">Worcestershire</option>

                        <option value="Flintshire">Flintshire</option>
                        <option value="Glamorgan">Glamorgan</option>
                        <option value="Merionethshire">Merionethshire</option>
                        <option value="Monmouthshire">Monmouthshire</option>
                        <option value="Montgomeryshire">Montgomeryshire</option>
                        <option value="Pembrokeshire">Pembrokeshire</option>
                        <option value="Radnorshire">Radnorshire</option>
                        <option value="Anglesey">Anglesey</option>
                        <option value="Breconshire">Breconshire</option>
                        <option value="Caernarvonshire">Caernarvonshire</option>
                        <option value="Cardiganshire">Cardiganshire</option>
                        <option value="Carmarthenshire">Carmarthenshire</option>
                        <option value="Denbighshire">Denbighshire</option>

                        <option value="Aberdeen City">Aberdeen City</option>
                        <option value="Aberdeenshire">Aberdeenshire</option>
                        <option value="Angus">Angus</option>
                        <option value="Argyll and Bute">Argyll and Bute</option>
                        <option value="City of Edinburgh">City of Edinburgh</option>
                        <option value="Clackmannanshire">Clackmannanshire</option>
                        <option value="Dumfries and Galloway">Dumfries and Galloway</option>
                        <option value="Dundee City">Dundee City</option>
                        <option value="East Ayrshire">East Ayrshire</option>
                        <option value="East Dunbartonshire">East Dunbartonshire</option>
                        <option value="East Lothian">East Lothian</option>
                        <option value="East Renfrewshire">East Renfrewshire</option>
                        <option value="Eilean Siar">Eilean Siar</option>
                        <option value="Falkirk">Falkirk</option>
                        <option value="Fife">Fife</option>
                        <option value="Glasgow City">Glasgow City</option>
                        <option value="Highland">Highland</option>
                        <option value="Inverclyde">Inverclyde</option>
                        <option value="Midlothian">Midlothian</option>
                        <option value="Moray">Moray</option>
                        <option value="North Ayrshire">North Ayrshire</option>
                        <option value="North Lanarkshire">North Lanarkshire</option>
                        <option value="Orkney Islands">Orkney Islands</option>
                        <option value="Perth and Kinross">Perth and Kinross</option>
                        <option value="Renfrewshire">Renfrewshire</option>
                        <option value="Scottish Borders">Scottish Borders</option>
                        <option value="Shetland Islands">Shetland Islands</option>
                        <option value="South Ayrshire">South Ayrshire</option>
                        <option value="South Lanarkshire">South Lanarkshire</option>
                        <option value="Stirling">Stirling</option>
                        <option value="West Dunbartonshire">West Dunbartonshire</option>
                        <option value="West Lothian">West Lothian</option>
                        <option value="Antrim">Antrim</option>
                        <option value="Armagh">Armagh</option>
                        <option value="Down">Down</option>
                        <option value="Fermanagh">Fermanagh</option>
                        <option value="Derry and Londonderry">Derry and Londonderry</option>
                        <option value="Tyrone">Tyrone</option>                
                        <option value="Other">Other</option>      
                    </select>
                </Dropdown>
            )}
            {requiredData.includes("regionNW") && (
                <Dropdown>
                    <label>Region</label>
                    <select ref={regionNWRef} required>
                        <option value="">Select an Option</option>
                        <optgroup label="Cheshire & Warrington">
                            <option value="Cheshire East">Cheshire East</option>
                            <option value="Warrington">Warrington</option>
                            <option value="Cheshire West and Chester">Cheshire West and Chester</option>
                        </optgroup>
                        <optgroup label="Lancashire">
                            <option value="Burnley">Burnley</option>
                            <option value="Chorley">Chorley</option>
                            <option value="Fylde">Fylde</option>
                            <option value="Hyndburn">Hyndburn</option>
                            <option value="Lancaster">Lancaster</option>
                            <option value="Pendle">Pendle</option>
                            <option value="Preston">Preston</option>
                            <option value="Ribble Valley">Ribble Valley</option>
                            <option value="Rossendale">Rossendale</option>
                            <option value="South Ribble">South Ribble</option>
                            <option value="West Lancashire">West Lancashire</option>
                            <option value="Wyre">Wyre</option>
                            <option value="Blackburn with Darwen">Blackburn with Darwen</option>
                            <option value="Blackpool">Blackpool</option>
                        </optgroup>
                        <optgroup label="Liverpool City Region">
                            <option value="Halton">Halton</option>
                            <option value="Knowsley">Knowsley</option>
                            <option value="Liverpool">Liverpool</option>
                            <option value="Sefton">Sefton</option>
                            <option value="St Helens">St Helens</option>
                            <option value="Wirral">Wirral</option>
                        </optgroup>
                        <optgroup label="Greater Manchester">
                            <option value="Bolton">Bolton</option>
                            <option value="Bury">Bury</option>
                            <option value="Manchester">Manchester</option>
                            <option value="Oldham">Oldham</option>
                            <option value="Rochdale">Rochdale</option>
                            <option value="Salford">Salford</option>
                            <option value="Stockport">Stockport</option>
                            <option value="Tameside">Tameside</option>
                            <option value="Trafford">Trafford</option>
                            <option value="Wigan">Wigan</option>
                        </optgroup>
                        <optgroup label="Cumbria">
                            <option value="Allerdale">Allerdale</option>
                            <option value="Carlisle">Carlisle</option>
                            <option value="Copeland">Copeland</option>
                            <option value="Barrow-in-Furness">Barrow-in-Furness</option>
                            <option value="Eden">Eden</option>
                            <option value="South Lakeland districts">South Lakeland districts</option>
                        </optgroup>
                        <option value="Other">Other</option>
                    </select>
                </Dropdown>
            )}
            {requiredData.includes("neurodiversity") && (
                <Dropdown>
                    <label>Do you identify as neurodiverse (e.g., autism, ADHD, dyslexia, or other neurological differences)?</label>
                    <select ref={neurodiversityRef} required>
                        <option value="">Select an Option</option>
                        <option value="Yes">Yes</option> 
                        <option value="No">No</option> 
                        <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                </Dropdown>
            )}
            {requiredData.includes("ethnicity") && (
                <Dropdown>
                    <label>Which of the following best describes your ethnicity?</label>
                    <select ref={ethnicityRef} required>
                        <option value="">Select an Option</option>                        
                        <option value="English/Welsh/Scottish/Northern Irish/British">English/Welsh/Scottish/Northern Irish/British</option>
                        <option value="Irish">Irish</option>
                        <option value="Gypsy or Irish Traveller">Gypsy or Irish Traveller</option>
                        <option value="Any Other White background">Any Other White background</option>
                        <option value="White and Black Caribbean">White and Black Caribbean</option>
                        <option value="White and Black African">White and Black African</option>
                        <option value="White and Asian">White and Asian</option>
                        <option value="Any Other Mixed/Multiple ethnic background">Any Other Mixed/Multiple ethnic background</option>
                        <option value="Indian">Indian</option>
                        <option value="Pakistani">Pakistani</option>
                        <option value="Bangladeshi">Bangladeshi</option>
                        <option value="Chinese">Chinese</option>
                        <option value="Any Other Asian background">Any Other Asian background</option>
                        <option value="African">African</option>
                        <option value="Caribbean">Caribbean</option>
                        <option value="Any Other Black/African/Caribbean background">Any Other Black/African/Caribbean background</option>
                        <option value="Arab">Arab</option>
                        <option value="Any Other ethnic group">Any Other ethnic group</option>
                    </select>
                </Dropdown>
            )}
            {requiredData.includes("aspiration") && (
                <Dropdown>
                    <label>What best describes you?</label>
                    <select ref={aspirationRef} required>
                        <option value="">Select an Option</option>
                        <option value="No idea whether tech is for me">No idea whether tech is for me</option> 
                        <option value="Interested in tech">Interested in tech</option> 
                        <option value="Considering returning to work and this could be an option">Considering returning to work and this could be an option</option> 
                        <option value="trying to figure out which training I should get">trying to figure out which training I should get</option> 
                    </select>
                </Dropdown>
            )}

            {skillsToCheck.length !== 0 && (
                <SkillsSection>
                    <p>Select any of the below skills you feel you have:</p>
                    {skillsToCheck.map((skill, index) => <Skill key={index} className={selectedSkills.includes(skill) ? "selected" : ""} onClick={() => handleToggleSkill(skill)}>{skill}</Skill> )}
                </SkillsSection>
            )}

            <Checkbox htmlFor="TsandCs">
                <input ref={termsRef} type="checkbox" id="TsandCs" />
                I consent to my information being used for the purpose of this attribute questionnaire
                <div className="customCheckbox"></div>
            </Checkbox>

            {specialAccount === "cyberLocal" && (
                <CyberLocalText>
                    <p><b>Your Privacy Matters</b> We take data protection seriously. Your information will be handled securely in line with GDPR and used only for this project’s delivery and monitoring. We’ll never share your personal data without your consent. You have the right to access, rectify, or request the deletion of your data at any time. For more information on how we handle your data, or to exercise your rights, contact <a href="mailto:Kim.Jackson@wearecodenation.com?subject=digiDNA Data">Kim.Jackson@wearecodenation.com</a></p>
                    <Checkbox htmlFor="marketing">
                        <input ref={marketingRef} type="checkbox" id="marketing" />
                        <b>I consent to receiving marketing communications</b>: I agree to receive updates, and promotional offers Code Nation about relevant products and services. I understand I can withdraw my consent at any time by contacting Yasmin.Fennell@wearecodenation.com
                        <div className="customCheckbox"></div>
                    </Checkbox>
                    <p><b>Ready to discover your tech potential?</b> Let’s go!</p>
                </CyberLocalText>
            )}

            <ErrorMessage>{errMsg}</ErrorMessage>
            <FormButton type="submit">Register</FormButton>
        </RegisterForm>
    )
}

export default RegisterUser;

const RegisterForm = styled.form`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: var(--bg-main);
    padding: 50px;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    

    h1 {
        margin: 0;
        font-size: 48px;
    }

    .welcomeMsg {
        font-size: 18px;
        width: 300px;
        margin: 0 0 20px;
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }

    select {
        margin: 5px 0;
    }

    & > div {
        background: var(--bg-main);
        margin: 10px 0;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
    }

    & > .textType {
        padding: 25px 10px 10px;
    }

    .tandc-closed {
        overflow: hidden;
        white-space: nowrap;
    }

    #tandc {
        background-color: var(--secondary-gray);
        padding: 10px;
        border-radius: 5px;
        border: 1px solid black;
        text-overflow: ellipsis;
        width: 350px;
        box-sizing: border-box;
        margin-bottom: 0;
    }

    #readMoreBtn {
        margin-top: 5px;
        color: var(--blue);
        cursor: pointer;
    }

    @media ${device.small} {
        width: 100%;
        padding: 20px;
        margin: 50px 5px 0;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin: 0;
    height: 19px;
    margin-top: 10px;
`;

const SkillsSection = styled.div`
    padding-bottom: 5px;
`;

const Skill = styled.span`
    background: var(--secondary-gray);
    padding: 10px 15px;
    display: inline-block;
    border-radius: 40px;
    margin: 5px 5px 0 0 ;
    font-size: var(--font-small);
    cursor: pointer;

    &.selected {
        background: var(--accent);
        color: white;
    }
`;

const CyberLocalText = styled.div`
    p {
        font-size: var(--font-small);
        margin: 1.5em 0;
    }

    li {
        font-size: var(--font-small);
    }
`;