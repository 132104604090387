import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PageTransition from "../components/PageTransition.js";
import { getGroupRegister, getYourResults } from "../utils/index.js";
import styled from "styled-components";
import QuestionCard from "../components/QuestionCard.js";
import SpiderChart from "../components/SpiderChart.js";
import { device } from "../styles/Responsive.style.js";
import AttributeInfo from "../components/Modals/AttributeInfo.js";
import { predictedJobs } from "../utils/predictedJobs.js";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SummaryReport from "../components/PDFResultSummary.js";
import AttributeBox from "../components/AttributeBox.js";
import { useUser } from "../context/UserContext.js";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();
const Results = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [userResults, setUserResults] = useState({});
    const [questionSections, setQuestionSections] = useState([]);
    const [activeSection, setActiveSection] = useState(0);
    const [quizData, setQuizData] = useState({});
    const [spiderData, setSpiderData] = useState({});
    const [bestJobs, setBestJobs] = useState([]);
    const [summaryData, setSummaryData] = useState(null);
    const [scoreMinMax, setScoreMinMax] = useState({min: 0, max: 0});
    const [userExtraInfo, setUserExtraInfo] =  useState({});
    const [isSpecialAccount, setIsSpecialAccount] = useState(false);

    const [moreInfo, setMoreInfo] = useState(false);
    const questionsTop = useRef();
    const { userName } = useUser();

    let { quizId } = useParams();

    const handleInitialFetch = async () => {
        let data = await getYourResults(quizId);
        let special = await getGroupRegister(null);
        setUserResults(data.userAnswers);
        setUserExtraInfo(data.userExtraInfo);
        setIsSpecialAccount(special.specialAccount)

        let updatedQuiz = {
            ...data.quizInfo,
            title: data.quizInfo.title === "General Questionnaire" && special.specialAccount === "cyberLocal" ? "Cyber Questionnaire" : data.quizInfo.title
        }

        setQuizData(updatedQuiz);

        setScoreMinMax({
            min: data.quizInfo.questions.reduce((p,c) => {
                let highestForQusetion = c.answers.reduce((q, d) => d.points < q ? d.points : q, 0) 
                return highestForQusetion < p ? highestForQusetion : p
            }, 0),
            max: data.quizInfo.questions.reduce((p,c) => {
                let highestForQusetion = c.answers.reduce((q, d) => d.points > q ? d.points : q, 0) 
                return highestForQusetion > p ? highestForQusetion : p
            }, 0)
        })

        let sectionSize = 11;
        let questions = [];
        for (let i = 0; i < data.quizInfo.questions.length; i += sectionSize) {
            const section = data.quizInfo.questions.slice(i, i + sectionSize);
            questions.push(section)
        }
        setQuestionSections(questions)

        // get average of scores for each attribute
        let attributes = [...new Set(data.quizInfo.questions.map(q => q.attribute))]
        let scores = attributes.map((el) => [])
        data.userAnswers.answers.forEach((answer) => {
            let questionObj = data.quizInfo.questions.find(q => q._id === answer.question_id);
            let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
            scores[attributes.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
        })

        let avgScores = scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1))
        setSpiderData({ attribute: attributes.map((att,i) => {if(i === 0 || i ===5) return att;let a = att.split("&"); if(a.length > 1)a[0] += " &"; return a}), avgScores: avgScores})
        
        const token = cookies.get("token");
        const decoded = jwtDecode(token);
        setSummaryData({ name: decoded.name.split(",").join(""), dateCompleted: new Date(data.userAnswers.date_completed).toLocaleDateString("en-GB", {year: "numeric", month: "short", day:"numeric"}),title: data.quizInfo.title ,attributes: attributes, scores: avgScores,jobs: predictedJobs(attributes, avgScores)})
        
        setBestJobs(predictedJobs(attributes, avgScores))

        setIsLoading(false);
    }

    useEffect(() => {
        // show off the loading screen
        setTimeout(async () => {
            await handleInitialFetch();
        }, 750);
    }, [])

    return (
        <ResultsWrapper>
            <TextHeader>
                <h1>{quizData.title}</h1>
                <h3>Completed on {new Date(userResults.date_completed).toLocaleDateString("en-GB", {year: "numeric", month: "short", day:"numeric"})}</h3>
                <p>{quizData.description}</p>

                {(!isLoading && summaryData?.image !== undefined) && (
                    <>
                        <PDFDownloadLink className="downloadPdf" document={SummaryReport({summaryData: summaryData})} fileName="digiDNA-Summary.pdf">
                            {({ loading }) => {
                                return loading ? "Loading document..." : "Download Summary"
                            }}
                        </PDFDownloadLink>
                    </>

                )}
                

                <h2>Results</h2>
                <p>Your scores have been collected and plotted against the {spiderData.attribute && spiderData.attribute.length} attributes of a person in the tech industry, with {scoreMinMax.max} being the perfect score. It's important to note, this view serves as a guide to show where you are against those attributes, recognised as being important in a digital role.</p>

                <ResultsSection>
                    {!isLoading && (
                        <div className="radarWrapper">
                            <SpiderChart categories={spiderData.attribute} pointRange={scoreMinMax} series={[{name: quizData.title, data: spiderData.avgScores}]} title={`${userName}'s digiDNA Attributes`} setSummaryData={setSummaryData} forDoc={true}/>
                            <SpiderChart categories={spiderData.attribute} pointRange={scoreMinMax} series={[{name: quizData.title, data: spiderData.avgScores}]} title={`${userName}'s digiDNA Attributes`} setSummaryData={setSummaryData} forDoc={false}/>
                        </div>
                    )}
                    <ResultRightSection>
                        <AttributeBox oldQuiz={quizData.archived}/>
                    </ResultRightSection>
                </ResultsSection>
                
                <h2 className="jobTitle">Jobs potentially suited to you</h2>
                {isSpecialAccount === "cyberLocal" && (
                    <>
                        {bestJobs.find(j => j.job === "Cyber Security Analyst") ? (
                            <>
                                <p>Thank you for completing digiDNA. We are excited to share that your results show an alignment with the attributes desirable for a career in cyber security! This means you have the potential to excel in this exciting and rapidly growing field.</p>
                                <p>Based on your results, we’ve identified the following opportunities to help you take your next steps.</p>
                                <p>You have also unlocked access to a Cyber Inspire session and one to one Employability support session delivered by Code Nation!</p>
                                <p>You will receive an email from xxxxx in the next few days with instructions of how to book your sessions. Please remember to check your junk if you don’t receive a link!</p>
                                <p>We look forward to meeting you soon!</p>
                            </>
                        ) : (
                            <>
                                <p>Thank you for completing digiDNA. Your results show that, while your current attributes do not align strongly with the requirements of a cyber security role at this time, there are exciting opportunities for you in other areas of the tech industry!</p>
                                <p>Based on your unique attributes and strengths, we’ve identified the following roles where you could thrive.  These roles may provide an excellent starting point to explore and develop your tech career.</p>
                                <p>However, this doesn’t mean a career in cyber security is out of reach! Many individuals build the necessary attributes and skills over time through training, experience, and passion.</p>
                                <p>Remember, everyone’s journey is different, and career paths can take many exciting turns.</p>
                            </>
                        )}
                    </>
                )}
                <PredictedJobsSection>
                    {bestJobs.map((job, i) => {
                        return (
                            <div key={i}>
                                <h2>{job?.job}</h2>
                                <p>{job?.desc}</p>
                                <div className="job-section_links">
                                    <a target="_blank" rel="noreferrer" href={`https://www.reed.co.uk/jobs/${job?.job.split(' ').join('-')}-jobs-in-${(userExtraInfo?.region) ? userExtraInfo?.region.split(' ').join('%20') : userExtraInfo?.regionNW.split(' ').join('%20')}?proximity=20`}>Find Related Jobs</a>
                                    {job.job === "Service Desk Analyst" || job.job === "Project Manager" ? (
                                        <>
                                            <a target="_blank" rel="noreferrer" href={`https://nationalcareers.service.gov.uk/find-a-course/page?searchTerm=computer%20science&distance=15%20miles&town=${(userExtraInfo?.region) ? userExtraInfo?.region.split(' ').join('%20') : userExtraInfo?.regionNW.split(' ').join('%20')}&orderByValue=Distance&startDate=Anytime&courseType=&sectors=&learningMethod=&courseHours=&courseStudyTime=&filterA=true&page=1&D=1&coordinates=&campaignCode=&qualificationLevels=`}>Find Related Courses</a>
                                            <a target="_blank" rel="noreferrer" href={`https://digital.ucas.com/coursedisplay/results/courses?searchTerm=computer%20science&&studyYear=2025&destination=Undergraduate&regions=North%20West%20England&postcodeDistanceSystem=imperial&pageNumber=1&sort=MostRelevant&clearingPreference=None`}>Find Related Higher Education Courses</a>
                                        </>
                                    ) : (
                                        <>
                                            <a target="_blank" rel="noreferrer" href={`https://nationalcareers.service.gov.uk/find-a-course/page?searchTerm=${job?.job.split(' ').join('%20')}&distance=15%20miles&town=${(userExtraInfo?.region) ? userExtraInfo?.region.split(' ').join('%20') : userExtraInfo?.regionNW.split(' ').join('%20')}&orderByValue=Distance&startDate=Anytime&courseType=&sectors=&learningMethod=&courseHours=&courseStudyTime=&filterA=true&page=1&D=1&coordinates=&campaignCode=&qualificationLevels=`}>Find Related Courses</a>
                                            <a target="_blank" rel="noreferrer" href={`https://digital.ucas.com/coursedisplay/results/courses?searchTerm=${job?.job.split(' ')[0]}&studyYear=2025&destination=Undergraduate&regions=North%20West%20England&postcodeDistanceSystem=imperial&pageNumber=1&sort=MostRelevant&clearingPreference=None`}>Find Related Higher Education Courses</a>
                                        </>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </PredictedJobsSection>

                <h2>Your answers</h2>
            </TextHeader>

            <AnswerSection>

                <Sidebar>
                    {questionSections.map((section, index) => {
                        return (
                            <button 
                                key={index} 
                                id={index === activeSection ? "active" : ""} 
                                onClick={() => {setActiveSection(index);questionsTop.current?.scrollIntoView({behaviour: 'smooth'})}}
                            >Section {index + 1}</button>
                        )
                    })}
                </Sidebar>

                <form ref={questionsTop}>
                    {questionSections[activeSection]?.map((question, index) => {
                        return(
                            <QuestionCard 
                                key={index} 
                                questionNum={index + 1} 
                                totalQuestions={questionSections[activeSection].length} 
                                questionInfo={question} 
                                savedAnswerId={userResults.answers[index + (questionSections[0].length * activeSection)].answer_id} 
                                changeAns={null} 
                                isDisabled={true}
                            />
                        )
                    })}
                </form>
            </AnswerSection>

            <AttributeInfo showModal={moreInfo} setShowModal={setMoreInfo}/>
            <PageTransition isLoading={isLoading} />
        </ResultsWrapper>
    )
}

export default Results;

const ResultsWrapper = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 25px;

    .jobTitle {
        margin-bottom: 10px;
    }
`;

const TextHeader = styled.header`
   width: 100%;
   max-width: 1500px;

   h1 {
        margin: 25px 0 0 0;
        font-size: var(--font-large);
   }
   h3 {
        margin: 0;
        font-weight: 400;
        color: var(--accent);
        font-size: var(--font-medium);
   }

   .downloadPdf {
        border: 3px solid var(--accent);
        border-radius: 5px;
        background-color: var(--accent);
        font-size: var(--font-small);
        font-weight: 900;
        color: white;
        cursor: pointer;
        letter-spacing: 1px;
        box-shadow: 0 0 0 0px white inset;
        transition: all 0.2s;
        text-decoration: none;
        padding: 5px 15px;
        margin: 0 10px 0 0;

        &:hover {
            box-shadow: 0 0 0 2px white inset;
            background-color: var(--accent-hover);
        }
   }
`;

const ResultsSection = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    .radarWrapper {
        width: 50%;
    }

    @media ${device.medium} {
        flex-direction: column;
        align-items: center;

        .radarWrapper {
            width: 100%;
        }
    }
`;

const ResultRightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    & > p {
        color: var(--blue);
        cursor: pointer;
        margin: 10px 0 0;
        font-size: var(--font-small);

        &:hover {
            text-decoration: underline;
        }
    }

    @media ${device.medium} {
        width: 100%;
    }
`;

const PredictedJobsSection = styled.main`
    display: flex;
    gap: 5px;

    & > div {
        background-color: var(--primary-gray);
        border-radius: 10px;
        padding: 15px;
        box-sizing: border-box;
        width: 33%;
        display: flex;
        flex-direction: column;
    }

    h2 {
        margin: 0 0 10px 0;
    }

    p {
        margin: 0;
    }

    .job-section_links {
        margin-top: auto;
    }

    .job-section_links a {
        display: block;
        padding: 10px 20px;
        background: var(--bg-main);
        border: 3px solid var(--accent);
        border-radius: 5px;
        text-decoration: none;
        font-weight: 900;
        font-size: var(--font-small);
        margin: 10px 0 0;
        color: var(--accent);
        transition: all 0.2s;

        &:hover {
            background: var(--accent);
            color: var(--bg-main);
        }
    }

    @media ${device.medium} {
        flex-direction: column;
        div {
            margin: 10px 0;
            width: 100%;
        }
    }
`;

const AnswerSection = styled.section`
    display: flex;
    align-items: flex-start;
    max-width: 1500px;
    width: 100%;

    form {
        margin: 0;
        flex-grow: 1;
    }

    @media ${device.small} {
        flex-direction: column;
    }
`;

const Sidebar = styled.aside`
    position: sticky;
    top: 25px;
    margin: 25px 0 0 0;
    width: 250px;
    min-width: 140px;
    height: auto;
    background-color: var(--primary-gray);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;

    button:not(:last-child) {
        border-bottom: 1px solid var(--secondary-gray);
    }

    button {
        width: 100%;
        text-align: left;
        padding: 10px;
        border-radius: 0px;
        font-size: var(--font-small);
        border: none;
        background-color: var(--primary-gray);
        cursor: pointer;
        position: relative;
    }

    #active {
        background-color: var(--secondary-gray);
    }

    .complete::before {
        position: absolute;
        content: '';
        height: 25px;
        width: 25px;
        background-color: var(--accent);
        border-radius: 50%;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
      
    .complete::after {
        position: absolute;
        content: '';
        height: 10px;
        width: 4px;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        right: 18px;
        top: calc(50% - 1px);
        transform: translateY(-50%) rotate(45deg);
    }

    @media ${device.small} {
        position: static;
        width: 100%;
    }
`;