import styled from "styled-components";
import { device } from "../../styles/Responsive.style";
import { useEffect, useRef } from "react";
import { useUser } from "../../context/UserContext";


const ModalTemplate = ({ children, showModal, setShowModal }) => {
    const { blurryBg, setBlurryBg } = useUser();
    const modalContent = useRef();

    useEffect(() => {
        if (showModal) {
            setBlurryBg(true);
        }
    }, [showModal])

    useEffect(() => {
        if (!blurryBg) {
            setShowModal(false);
        }
    }, [blurryBg])

    const closeModal = () => {
        setShowModal(false)
        setBlurryBg(false)
    }

    return (
        <ModalWrapper ref={modalContent} className={showModal ? "fade-in" : "fade-out"}>
            <CloseModalButton aria-label="Close Modal" onClick={closeModal} />
            {children}
        </ModalWrapper>
    );
};

export default ModalTemplate;

const ModalWrapper = styled.section`
    background-color: var(--bg-main);
    padding: 50px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 20;
    box-sizing: border-box;
    width: calc(100vw - 40px);
    max-width: 1000px;
    max-height: calc(100vh - 40px);
    overflow-y: scroll;

    &.fade-out {
        visibility: hidden;
        opacity: 0;
        transform: translate(-50%, -0%);
        transition: visibility 0s linear 0.2s, transform 0.2s, opacity 0.2s;
    }
    &.fade-in {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: visibility 0s linear 0s, transform 0.2s, opacity 0.2s;
    }

    h1, h2 {
        margin: 0;
        font-size: var(--font-large);
    }

    h3 {
        font-size: var(--font-small);
        margin: 10px 0 5px;
    }

    p {
        font-size: var(--font-small);
        margin: 5px 0 10px;
    }

    @media ${device.medium} {
        top: 10px;
        padding: 20px;
        width: calc(100vw - 20px);

        &.fade-out {
            transform: translate(-50%, 50%);
        }
        &.fade-in {
            transform: translate(-50%, 0%);
        }
    }
`;

const CloseModalButton = styled.button`
    position: absolute;
    right: 24px;
    top: 24px;
    border: none;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    cursor: pointer;
    background-color: var(--bg-main);
    transition: all 0.2s;


    &:hover {
        opacity: 1;
    }
    &:before, &:after {
        position: absolute;
        left: 15px;
        top: -5px;
        content: ' ';
        height: 42px;
        width: 2px;
        background-color: var(--dark-bg-hover);
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;