import styled from "styled-components"
import GroupCard from "./GroupCard";
import { device } from "../../styles/Responsive.style";
import { useAdmin } from "../../context/AdminContext";
import AddGroup from "../Modals/Groups/AddGroup";
import { useState } from "react";
import AddGroupSuccess from "../Modals/Groups/AddGroupScuccess";
import Plus from "../../assets/icons/Plus";

const GroupSection = () => {

    const { adminData, setNoInviteModal, setShowAddUserModal, allUserData } = useAdmin();

    const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
    const [openAddGroupSuccessModal, setOpenAddGroupSuccessModal] = useState(false);

    return (
        <>
            <Wrapper>
                <UserStats>
                    <div>
                        <p>Total Users</p>
                        <h4>{allUserData.length}</h4>
                    </div>
                    <BottomSection>
                        <div>
                            <p>Invites Remaining</p>
                            <h4>{adminData.invites}</h4>
                        </div>
                    </BottomSection>
                </UserStats>

                {adminData?.groups?.length === 0 && (
                    <NoGroupMsg>
                        <p>To invite a user you must first add a group.</p>
                    </NoGroupMsg>
                )}
                
                {adminData?.groups !== undefined && (
                    <>
                        {adminData?.groups.map((g, index) => <GroupCard key={index} group_info={g} />)}
                    </>
                )}
                
                <AddGroupBtn onClick={() => setOpenAddGroupModal(true)}>
                    <button>
                        <Plus strokeCol="black" width="41px" />
                        <p>Add Group</p>
                    </button>
                </AddGroupBtn>

            </Wrapper>
            <AddGroup showModal={openAddGroupModal} setShowModal={setOpenAddGroupModal} setOpenAddGroupSuccessModal={setOpenAddGroupSuccessModal}/>
            <AddGroupSuccess showModal={openAddGroupSuccessModal} setShowModal={setOpenAddGroupSuccessModal} />
        </>
    )
}

export default GroupSection;

const Wrapper = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: 20px 0;

    & > div {
        width: calc(25% - 9px);
    }

    @media ${device.large} {
        & > div {
            width: calc((100%/3) - 9px);
        }
    }

    @media ${device.medium} {
        justify-content: space-between;
        & > div {
            width: calc((100%/2) - 9px);
        }
    }

    @media ${device.small} {
        justify-content: space-between;
        gap: 8px;
        & > div {
            width: calc((100%/2) - 6px);
        }
    }
`;

const UserStats = styled.div`
    background: var(--accent);
    background: linear-gradient(-13deg, var(--orange) 0%, var(--accent) 100%);
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 var(--gray-light);
    padding: 15px;
    width: 325px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        margin: 0;
        color: var(--text-light);
        font-size: var(--font-small);
    }

    h4 {
        margin: 0;
        font-size: calc(var(--font-large) * 1.5);
        color: white;
    }


    @media ${device.small} {
        h4 {
            font-size: var(--font-large);
        }
    }
`;

const NoGroupMsg = styled.div`
    padding: 15px;
    width: 325px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        margin: 0;
        text-align: center;
    }
`;

const BottomSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const AddGroupBtn = styled.div`
    padding: 51px;
    width: 325px;
    align-self: stretch;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background-color: var(--bg-main);
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: var(--bg-main-light);
    }

    & > button {
        background-color: var(--bg-main-light);
        font-weight: 900;
        font-size: var(--font-small);
        height: 100%;
        border: none;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        border-radius: 10px;
        transition: all 0.2s;
    }

    &:hover > button {
        background-color: var(--gray-light);
    }

    p {
        margin: 10px 0 0;
    }

    @media ${device.small} {
        height: 167px;
        padding: 0px;
    }
`;