import { useEffect, useState } from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { Dropdown } from "../styles/Form.styles";
import { useAdmin } from "../context/AdminContext";
import { device } from "../styles/Responsive.style";
import { predictedJobs } from "../utils/predictedJobs";

const AdminStats = () => {
    const { allUserData, allQuizzes } = useAdmin();
    const [selectedRegion, setSelectedRegion] = useState("All Regions");
    const [regionStats, setRegionStats] = useState({});

    const [userLineChart, setUserLineChart] = useState({});
    const [pieChartData, setPieChartData] = useState({});
    const [userStats, setUserStats] = useState({ total: 0, neverReg: 0, completeQuiz: 0, cyberRecommended: 0 });

    const regions = {
        "Cheshire & Warrington": ["Cheshire East", "Warrington", "Cheshire West and Chester"],
        "Lancashire": ["Burnley", "Chorley", "Fylde", "Hyndburn", "Lancaster", "Pendle", "Preston", "Ribble Valley", "Rossendale", "South Ribble", "West Lancashire", "Wyre", "Blackburn with Darwen", "Blackpool"],
        "Liverpool City Region": ["Halton", "Knowsley", "Liverpool", "Sefton", "St Helens", "Wirral"],
        "Greater Manchester": ["Bolton", "Bury", "Manchester", "Oldham", "Rochdale", "Salford", "Stockport", "Tameside", "Trafford", "Wigan"],
        "Cumbria": ["Allerdale", "Carlisle", "Copeland", "Barrow-in-Furness", "Eden", "South Lakeland districts"],
    }

    const pieChartOptions = {
        chart: {
            fontFamily: 'Gilroy',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            }     
        },
        legend: {
            position: "bottom",
            labels: {
                colors: Array(30).fill("var(--text-main)")
            }
        },
        itemMargin: {
            horizontal: 0,
            vertical: 0
        }
    }

    const statsForRegion = (usersInRegion) => {
        let users = usersInRegion[selectedRegion];
        if (selectedRegion === "All Regions") {
            users = Object.values(usersInRegion).flat();
        }
        setPieChartData((prev) => {
            let dataPoints = ["ageRange", "gender", "neurodiversity", "ethnicity", "regionNW", "status"];
            let duplicate = { ...prev };
            dataPoints.forEach((d) => {
                duplicate[d] = users.reduce((acc, c) => {
                    if (c[d] && (c.status === "Full-Time Education" || c.status === "Unemployed")) {
                        acc[c[d]] = (acc[c[d]] || 0) + 1;
                    }
                    return acc;
                }, {});
                if (Object.keys(duplicate[d]).length === 0) {
                    duplicate[d] = null
                }
            });
            return duplicate;
        });
    }

    useEffect(() => {
        statsForRegion(regionStats);
    }, [selectedRegion])

    useEffect(() => {

        let selectedQuiz = allQuizzes.find(q => q.title === "General Questionnaire");
        let attributes = [...new Set(selectedQuiz.questions.map(q => q.attribute))];
        let totalCyberRoles = 0;
        let cyberRoleUserIds = [];
        allUserData.forEach((u) => {
            if(u.results.length === 0) return;
            if(u.results[0].answers.find(a => a.answer_id === null)) return;
            let scores = attributes.map((el) => [])
            let completedQuiz = allQuizzes.find(q => q._id === u.results[0].quizId)
            u.results[0].answers.forEach((answer) => {
                let questionObj = completedQuiz.questions.find(q => q._id === answer.question_id);
                let chosenAnsObj = questionObj.answers.find(ans => ans._id === answer.answer_id);
                scores[attributes.indexOf(questionObj.attribute)].push(chosenAnsObj.points);
            })

            let avgScores = scores.map((att) => (att.reduce(( p, c ) => p + c, 0 ) / att.length).toFixed(1));

            let jobs = predictedJobs(attributes, avgScores);
            if(jobs.find(j => j.job === "Cyber Security Analyst")){
                totalCyberRoles += 1
                cyberRoleUserIds.push(u._id)
            }
        })

        setUserStats({
            total: allUserData.length,
            cyberRecommended: totalCyberRoles,
            completeQuiz: allUserData.reduce((acc, c) => {
                if (c.results.length !== 0 && c.results[0].date_completed !== null) {
                    return acc + 1
                } else {
                    return acc
                }
            }, 0),
            targetRequiredUsers: allUserData.reduce((acc, c) => {
                if (c.extra_data?.status && (c.extra_data?.status === "Unemployed" || c.extra_data?.status === "Full-Time Education") && c.results.find((q) => q.date_completed)) {
                    return acc + 1;
                }
                return acc;
            }, 0),
            targetCyberTotal: cyberRoleUserIds.reduce((acc, c) => {
                let user = allUserData.find(u => u._id === c)
                if (user.extra_data?.status && (user.extra_data?.status === "Unemployed" || user.extra_data?.status === "Full-Time Education") && user.results.find((q) => q.date_completed)) {
                    return acc + 1;
                }
                return acc;
            }, 0),
            targetUnder18s: allUserData.reduce((acc, c) => {
                if (c.extra_data?.ageRange && c.extra_data?.ageRange === "14 - 18" && (c.extra_data?.status === "Unemployed" || c.extra_data?.status === "Full-Time Education") && c.results.find((q) => q.date_completed)) {
                    return acc + 1;
                }
                return acc;
            }, 0),
            targetFemaleUsers: allUserData.reduce((acc, c) => {
                if (c.extra_data?.gender && c.extra_data?.gender === "Female" && (c.extra_data?.status === "Unemployed" || c.extra_data?.status === "Full-Time Education") && c.results.find((q) => q.date_completed)) {
                    return acc + 1;
                }
                return acc;
            }, 0),
            targetEthnicUsers: allUserData.reduce((acc, c) => {
                if (c.extra_data?.ethnicity && c.extra_data?.ethnicity !== "English/Welsh/Scottish/Northern Irish/British" && (c.extra_data?.status === "Unemployed" || c.extra_data?.status === "Full-Time Education") && c.results.find((q) => q.date_completed)) {
                    return acc + 1;
                }
                return acc;
            }, 0),
            targetNeurodiverseUsers: allUserData.reduce((acc, c) => {
                if (c.extra_data?.neurodiversity && c.extra_data?.neurodiversity === "Yes" && (c.extra_data?.status === "Unemployed" || c.extra_data?.status === "Full-Time Education") && c.results.find((q) => q.date_completed)) {
                    return acc + 1;
                }
                return acc;
            }, 0)
        })

        let regionUserData = {}
        Object.keys(regions).forEach((r) => regionUserData[r] = []);
        allUserData.forEach((u) => {
            if (u.extra_data?.regionNW) {
                const matchingKeys = Object.keys(regions).filter(key => regions[key].includes(u.extra_data.regionNW));
                let userData = {...u.extra_data}
                userData.quizCompleted = (u.results.find((q) => q.date_completed)) ? true : false
                regionUserData[matchingKeys].push(userData)
            }
        })
        setRegionStats(regionUserData)
        statsForRegion(regionUserData)

        let userRegisterDates = allUserData.map((user) => new Date(parseInt(user._id.toString().substring(0, 8), 16) * 1000));

        const groupedByDay = userRegisterDates.reduce((acc, timestamp) => {
            const day = new Date(timestamp).toISOString().split('T')[0];

            acc[day] = (acc[day] || 0) + 1;
            return acc;
        }, {});

        const groupedArray = Object.entries(groupedByDay).map(([x, y]) => ({
            x: new Date(x).getTime(),
            y,
        }));

        setUserLineChart({
            chart: {
                fontFamily: 'Gilroy',
                foreColor: "var(--text-main)",
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#f5b32f"],
            stroke: {
                curve: 'smooth',
            },
            title: {
                text: "Users Registered Over Time",
                style: {
                    color: "var(--text-main)"
                }
            },
            yaxis: {
                stepSize: 1,
                title: {
                    text: "Users Registered To digiDNA"
                }
            },
            xaxis: {
                type: 'datetime',
            },
            series: [{
                name: "Users",
                data: groupedArray
            }],
        })
    }, [])

    return (
        <StatisticsSection>
            <TopStats>
                <StatCard>
                    <h3>Total Users Registered</h3>
                    <h2>{userStats.total}</h2>

                    <h3>Users who completed a questionnaire</h3>
                    <h2>{userStats.completeQuiz}</h2>

                    <h3>Users recommended for Cyber Security Analyst</h3>
                    <h2>{userStats.cyberRecommended}</h2>
                </StatCard>
                <BarChartWrapper>
                    {userLineChart.series && (
                        <ReactApexChart
                            options={userLineChart}
                            series={userLineChart.series}
                            type="bar"
                            height="100%"
                            width="100%"
                        />
                    )}
                </BarChartWrapper>
            </TopStats>
            <h2>Targets</h2>
            <p>
                The following stats are only made up of users who are unemployed or in
                full time education and have completed a questionnaire
            </p>
            <FlexiCardWrapper>
                <StatCard>
                    <h3>Total</h3>
                    <h4
                        className={
                            userStats.targetRequiredUsers < 1500
                                ? "red"
                                : userStats.targetRequiredUsers < 3000
                                    ? "orange"
                                    : "green"
                        }
                    >
                        {userStats.targetRequiredUsers}
                        <small>/3000</small>
                    </h4>
                    <h3>Cyber Recommended Users</h3>
                    <h4>{userStats.targetCyberTotal}</h4>
                </StatCard>
                <StatCard>
                    <h3>Age - 40% Under 18</h3>
                    <h4
                        className={
                            userStats.targetUnder18s < 600
                                ? "red"
                                : userStats.targetUnder18s < 1200
                                    ? "orange"
                                    : "green"
                        }
                    >
                        {userStats.targetUnder18s}
                        <small>/1200</small>
                    </h4>
                    <h3>Percentage of Target</h3>
                    <h4
                        className={
                            userStats.targetUnder18s === 0
                                ? "red"
                                : userStats.targetUnder18s / 1200 < 0.5
                                    ? "red"
                                    : userStats.targetUnder18s / 1200 < 1
                                        ? "orange"
                                        : "green"
                        }
                    >
                        {userStats.targetUnder18s === 0
                            ? 0
                            : (
                                (userStats.targetUnder18s / 1200) *
                                100
                            ).toFixed(1)}
                        %
                    </h4>
                </StatCard>
                <StatCard>
                    <h3>Gender - 40% Female</h3>
                    <h4
                        className={
                            userStats.targetFemaleUsers < 600
                                ? "red"
                                : userStats.targetFemaleUsers < 1200
                                    ? "orange"
                                    : "green"
                        }
                    >
                        {userStats.targetFemaleUsers}
                        <small>/1200</small>
                    </h4>
                    <h3>Percentage of Target</h3>
                    <h4
                        className={
                            userStats.targetFemaleUsers === 0
                                ? "red"
                                : userStats.targetFemaleUsers / 1200 <
                                    0.5
                                    ? "red"
                                    : userStats.targetFemaleUsers / 1200 <
                                        1
                                        ? "orange"
                                        : "green"
                        }
                    >
                        {userStats.targetFemaleUsers === 0
                            ? 0
                            : (
                                (userStats.targetFemaleUsers / 1200 ) *
                                100
                            ).toFixed(1)}
                        %
                    </h4>
                </StatCard>
                <StatCard>
                    <h3>Ethnicity - 40% Ethnic Minorities</h3>
                    <h4
                        className={
                            userStats.targetEthnicUsers < 600
                                ? "red"
                                : userStats.targetEthnicUsers < 1200
                                    ? "orange"
                                    : "green"
                        }
                    >
                        {userStats.targetEthnicUsers}
                        <small>/1200</small>
                    </h4>
                    <h3>Percentage of Target</h3>
                    <h4
                        className={
                            userStats.targetEthnicUsers === 0
                                ? "red"
                                : userStats.targetEthnicUsers / 1200 <
                                    0.5
                                    ? "red"
                                    : userStats.targetEthnicUsers / 1200 <
                                        1
                                        ? "orange"
                                        : "green"
                        }
                    >
                        {userStats.targetEthnicUsers === 0
                            ? 0
                            : (
                                (userStats.targetEthnicUsers / 1200 ) *
                                100
                            ).toFixed(1)}
                        %
                    </h4>
                </StatCard>
                <StatCard>
                    <h3>Neurodiversity - 10% Neurodiverse</h3>
                    <h4
                        className={
                            userStats.targetNeurodiverseUsers < 150
                                ? "red"
                                : userStats.targetFemaleUsers < 300
                                    ? "orange"
                                    : "green"
                        }
                    >
                        {userStats.targetNeurodiverseUsers}
                        <small>/300</small>
                    </h4>
                    <h3>Percentage of Target</h3>
                    <h4
                        className={
                            userStats.targetNeurodiverseUsers === 0
                                ? "red"
                                : userStats.targetNeurodiverseUsers / 300 <
                                    0.5
                                    ? "red"
                                    : userStats.targetNeurodiverseUsers / 300 <
                                        1
                                        ? "orange"
                                        : "green"
                        }
                    >
                        {userStats.targetNeurodiverseUsers === 0
                            ? 0
                            : (
                                (userStats.targetNeurodiverseUsers / 300) *
                                100
                            ).toFixed(1)}
                        %
                    </h4>
                </StatCard>
            </FlexiCardWrapper>
            <h2>Regional Stats</h2>
            <div>
                <Dropdown className="dropdown">
                    <select onChange={(e) => setSelectedRegion(e.target.value)}>
                        <option value="All Regions">All Regions</option>
                        <option value="Cheshire & Warrington">Cheshire & Warrington</option>
                        <option value="Lancashire">Lancashire</option>
                        <option value="Liverpool City Region">Liverpool City Region</option>
                        <option value="Greater Manchester">Greater Manchester</option>
                        <option value="Cumbria">Cumbria</option>
                    </select>
                </Dropdown>
                <div>
                    {selectedRegion === "All Regions" ? (
                        <>
                            <FlexiCardWrapper>
                                {Object.keys(regions).map((reg, i) => (
                                    <RegionStatCard key={i}>
                                        <div>
                                            <h3>Total Registered in <b>{reg}</b></h3>
                                            {regionStats[reg] && <h4>{regionStats[reg].length}</h4>}
                                        </div>
                                        <div>
                                            <h3>Registered - Unemployed</h3>
                                            {regionStats[reg] && <h4>{regionStats[reg].reduce((acc, u) => u.status === "Unemployed" ? acc + 1 : acc, 0)}</h4>}
                                        </div>
                                        <div>
                                            <h3>Registered - Full-Time Education</h3>
                                            {regionStats[reg] && <h4>{regionStats[reg].reduce((acc, u) => u.status === "Full-Time Education" ? acc + 1 : acc, 0)}</h4>}
                                        </div>
                                        <div>
                                            <h3>Completed - Unemployed</h3>
                                            {regionStats[reg] && <h4>{regionStats[reg].reduce((acc, u) => u.quizCompleted && u.status === "Unemployed" ? acc + 1 : acc, 0)}</h4>}
                                        </div>
                                        <div>
                                            <h3>Completed - In Full-Time Education</h3>
                                            {regionStats[reg] && <h4>{regionStats[reg].reduce((acc, u) => u.quizCompleted && u.status === "Full-Time Education" ? acc + 1 : acc, 0)}</h4>}
                                        </div>
                                    </RegionStatCard>
                                ))}
                            </FlexiCardWrapper>
                            <PieSection>
                                {pieChartData.ageRange && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Age of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.ageRange),
                                            }}
                                            series={Object.values(pieChartData.ageRange)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.gender && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Gender Identity of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.gender),
                                            }}
                                            series={Object.values(pieChartData.gender)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.neurodiversity && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Neurodiversity of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.neurodiversity),
                                            }}
                                            series={Object.values(pieChartData.neurodiversity)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.ethnicity && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Ethnicity of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.ethnicity),
                                            }}
                                            series={Object.values(pieChartData.ethnicity)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {regionStats && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Location of Users", style:{color:"var(--text-main)"}},
                                                labels: Object.keys(regionStats),
                                            }}
                                            series={Object.values(regionStats).map((u) => u.length)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.status && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Employment Status of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.status),
                                            }}
                                            series={Object.values(pieChartData.status)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                            </PieSection>
                        </>
                    ) : (
                        <>
                            <FlexiCardWrapper>
                                <StatCard>
                                    <h3>Total Users in {selectedRegion}</h3>
                                    <h4>{regionStats[selectedRegion].length}</h4>
                                </StatCard>
                                <StatCard>
                                    <h3>Unemployed</h3>
                                    <h4>{regionStats[selectedRegion].reduce((acc, u) => u.status === "Unemployed" ? acc + 1 : acc, 0)}</h4>
                                </StatCard>
                                <StatCard>
                                    <h3>Full-Time Education</h3>
                                    <h4>{regionStats[selectedRegion].reduce((acc, u) => u.status === "Full-Time Education" ? acc + 1 : acc, 0)}</h4>
                                </StatCard>
                                <StatCard>
                                    <h3>Unemployed who completed a questionnaire</h3>
                                    <h4>{regionStats[selectedRegion].reduce((acc, u) => u.quizCompleted && u.status === "Unemployed" ? acc + 1 : acc, 0)}</h4>
                                </StatCard>
                                <StatCard>
                                    <h3>Users in Full-Time Education who completed a questionnaire</h3>
                                    <h4>{regionStats[selectedRegion].reduce((acc, u) => u.quizCompleted && u.status === "Full-Time Education" ? acc + 1 : acc, 0)}</h4>
                                </StatCard>
                            </FlexiCardWrapper>
                            <PieSection>
                                {pieChartData.ageRange && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Age of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.ageRange),
                                            }}
                                            series={Object.values(pieChartData.ageRange)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.gender && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Gender Identity of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.gender),
                                            }}
                                            series={Object.values(pieChartData.gender)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.neurodiversity && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Neurodiversity of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.neurodiversity),
                                            }}
                                            series={Object.values(pieChartData.neurodiversity)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.ethnicity && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Ethnicity of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.ethnicity),
                                            }}
                                            series={Object.values(pieChartData.ethnicity)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.regionNW && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Location of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.regionNW),
                                            }}
                                            series={Object.values(pieChartData.regionNW)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                                {pieChartData.status && (
                                    <PieChartWrapper>
                                        <ReactApexChart
                                            options={{
                                                ...pieChartOptions,
                                                title: { text: "Employment Status of Users", style:{color:"var(--text-main)"} },
                                                labels: Object.keys(pieChartData.status),
                                            }}
                                            series={Object.values(pieChartData.status)}
                                            type="pie"
                                            height="100%"
                                            width="100%"
                                        />
                                    </PieChartWrapper>
                                )}
                            </PieSection>
                        </>
                    )}
                </div>
            </div>
        </StatisticsSection>
    );
};

export default AdminStats;

const StatisticsSection = styled.section`
    & > h2 {
        margin-bottom: 0;
    }
    & p {
        margin: 0 0 5px;
        font-size: var(--font-small);
    }
`;

const TopStats = styled.section`
    display: flex;

    @media ${device.medium} {
        flex-direction: column;
    }
`;

const FlexiCardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const RegionStatCard = styled.div`
    background: var(--primary-gray);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    & > div {
        width: fit-content;
    }
    & > div:nth-child(1) {
        width: 230px;
    }

    h4 {
        margin: 0;
        font-size: var(--font-medium);
    }
    h3 {
        margin: 0;
        font-size: var(--font-small);
        color: var(--gray);
        font-weight: 400;
    }
`;

const StatCard = styled.div`
    background: var(--primary-gray);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    min-width: fit-content;
    flex-grow: 1;

    h2 {
        margin: 0;
        font-size: var(--font-large);
    }
    h4 {
        margin: 0;
        font-size: var(--font-medium);
    }
    h3 {
        margin: 0;
        font-size: var(--font-small);
        color: var(--gray);
        font-weight: 400;
    }

    small {
        font-size: var(--font-medium);
    }

    .red {
        color: var(--red)
    }
    .orange {
        color: var(--orange)
    }
    .green {
        color: var(--green)
    }

    @media ${device.small} {
        width: 100%;
        min-width: 140px;
        flex-grow: 0;
    }
`;

const BarChartWrapper = styled.div`
    width: 70%;
    position: relative;
    z-index: 0;
    @media ${device.medium} {
        width: 100%;
    }
`;

const PieSection = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;

const PieChartWrapper = styled.div`
    width: 33%;

    & > div {
        margin: 0 auto;
       max-width: 350px; 
    }
    
    aspect-ratio: 1/1;

    @media ${device.medium} {
        width: 50%;
    }
    @media ${device.small} {
        width: 100%;
    }
`;