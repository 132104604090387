import styled from "styled-components";
import AttributeInfo from "./Modals/AttributeInfo";
import { useState } from "react";
import { device } from "../styles/Responsive.style";

const AttributeBox = ({oldQuiz}) => {
    const [moreInfo, setMoreInfo] = useState(false);

  return (
    <>
      <GridWrapper>
        {oldQuiz ? (
            <>
            <div>
          <ul>
            <li>Patient & Committed</li>
            <li>Efficiency & Improvement</li>
            <li>Resilient & Positive</li>
          </ul><br/>
          <p>WAY - How people approach their work, their attitude, and motivation</p>
        </div>
        <div>
          <ul>
            <li>Curious & Inquisitive</li>
            <li>Detailed & Technically Minded</li>
          </ul><br/>
          <p>KNOW - Knowledge-based traits relating to how people understand and learn</p>
        </div>
        <div>
          <ul>
            <li>Communication & Empathy</li>
            <li>Team Player & Collaborative</li>
          </ul><br/>
          <p>HUMAN - The way people interact and collaborate with others, teams, and customers</p>
        </div>
        <div>
          <ul>
            <li>Order & Logic</li>
            <li>Creative & Flexible</li>
            <li>Problem Solving</li>
          </ul><br/>
          <p>THINK - How people think about the world around them and the tasks they focus on</p>
        </div>
        </>
        ) : (
            <>
                <div>
                    <p>Adaptability & Resilience, Time Management, Growth Mindset</p><br/>
                    <p>WAY - How people approach their work, their attitude, and motivation</p>
                </div>
                <div>
                    <p>Curiosity, Attention to Detail</p><br/>
                    <p>KNOW - Knowledge-based traits relating to how people understand and learn</p>
                </div>
                <div>
                    <p>Communication Skills, Empathy, Team Collaboration</p><br/>
                    <p>HUMAN - The way people interact and collaborate with others, teams, and customers</p>
                </div>
                <div>
                    <p>Problem Solving, Critical Thinking</p><br/>
                    <p>THINK - How people think about the world around them and the tasks they focus on</p>
                </div>
            </>
        )}
        
      </GridWrapper>
      <p onClick={() => setMoreInfo(true)}>More Information</p>

      <AttributeInfo oldQuiz={oldQuiz} showModal={moreInfo} setShowModal={setMoreInfo}/>
    </>
  );
};

export default AttributeBox;

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 650px;
  margin: 20px;
  font-size: var(--font-small);

  div {
    width: 50%;
    background-color: rgba(245, 179, 47, 0.5);
    padding: 20px 10px;
    box-sizing: border-box;
  }

  div:nth-child(2),
  div:nth-child(3) {
    background-color: rgba(245, 179, 47, 0.25);
  }

  p {
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0 0 0 27px;
  }

  @media ${device.small} {
    margin: 20px;
  }
`;
