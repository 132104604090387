import PageTransition from "../components/PageTransition.js";
import styled from "styled-components";

import dnaImg from "../assets/images/dna.jpeg";
import employersIcon from "../assets/images/employersIcon.svg";
import individualIcon from "../assets/images/individualIcon.svg";
import productIcon from "../assets/images/productIcon.svg";
import radarDiagram from "../assets/images/radarDiagram.png";

import { device } from "../styles/Responsive.style.js";
import AttributeInfo from "../components/Modals/AttributeInfo.js";
import Carousel from "../components/Carousel.js";
import { useState } from "react";
import { FormButton } from "../styles/Button.styles.js";
import { useUser } from "../context/UserContext.js";
import AttributeBox from "../components/AttributeBox.js";

const Home = () => {
    const [moreInfo, setMoreInfo] = useState(false);

    const { setShowContactForm } = useUser();



    return (
        <>
            <Hero bgImg={dnaImg}>
                <h1>digi<b>DNA</b></h1>
                <h2>Discovering your future.</h2>
            </Hero>

            <main>
                <Section className="product">
                    <LimitWidth className="textLeft">
                        <TextWrapper className="left">
                            <h3>The Product</h3>
                            <p>Introducing digiDNA, a groundbreaking online tool designed to identify the core attributes essential for success in a variety of digital roles. 
                                <br/><br/>Developed in collaboration with industry leaders and testing specialists, digiDNA enables you to identify and unlock the untapped potential within your existing team. The tool has been tested across diverse sectors, including major financial institutions and leading global tech firms, with over a thousand learners. The feedback speaks volumes: "We were amazed to discover so many potential software developers within our own business"
                                <br/><br/>digiDNA can uncover colleagues with attributes to fill much needed roles, as standard, and the tool can easily be adapted to include roles specific to your organisation: Business Analyst, Data Analyst, Project Manager, Network Engineer, Cyber Security Analyst, Software Developer, Service Desk Analyst.
                                <br/><br/><b>Why digiDNA?</b>
                                <br/><br/>Discover Untapped Potential - Reveal employees who possess critical attributes like problem-solving, critical thinking, adaptability, resilience, and teamwork - key qualities for thriving in tech roles. Those attributes can often sit within individuals unidentified, within parts of the organisation, for example; contact centres, finance, operations and support functions.
                                <br/><br/>Bridge the Skills Gap - Transform your workforce from within, reducing reliance on external recruitment and minimising hiring costs.
                                <br/><br/>Accelerate Digital Transformation - Equip your organisation with the necessary digital skills to stay competitive in today's market.
                                <br/><br/>Enhance Employee Engagement - Foster growth and retention by providing career development opportunities, boosting morale and loyalty. Ready to Transform Your Team? 
                                <br/><br/>Don't let hidden talent go unnoticed. Unlock the potential within your organisation and drive innovation from within.
                            </p>
                        </TextWrapper>
                        <ImgWrapper>
                            <img src={productIcon} alt="individual icon"/>
                        </ImgWrapper>
                    </LimitWidth>
                </Section>
                <Carousel />
                <Section>
                    <LimitWidth>
                        <ImgWrapper>
                            <img src={employersIcon} alt="company building"/>
                        </ImgWrapper>
                        <TextWrapper className="right">
                            <h3>For Employers</h3>
                            <p>In search of a nuanced and effective approach to talent insight? DigiDNA offers a unique solution. 
                                <br/><br/>Our questionnaire can be customised to align with your organisation's specific needs, enhancing your ability to identify attributes in new hires that reflect your existing team's strengths. It serves as an instrument for discerning attributes in prospective candidates that are reflective of the core competencies within your team. 
                                <br/><br/>Engage with us to explore how DigiDNA can be customised for your enterprise, enhancing your talent acquisition strategy.</p>
                        </TextWrapper>
                    </LimitWidth>
                </Section>
                <Section>
                    <LimitWidth className="textLeft">
                        <TextWrapper className="left">
                            <h3>For Individuals</h3>
                            <p>Discover the attributes sought after in the burgeoning digital economy with DigiDNA's questionnaire.  
                                <br/><br/>This tool is your guide to uncovering and showcasing the attributes that are in high demand in the digital sector. It offers a refined approach for individuals to demonstrate their innate strengths, simplifying the talent identification process for employers and positioning you as a standout candidate in the competitive digital landscape. 
                            </p>
                        </TextWrapper>
                        <ImgWrapper>
                            <img src={individualIcon} alt="individual icon"/>
                        </ImgWrapper>
                    </LimitWidth>
                </Section>
                <Section>
                    <LimitWidth>
                        <ImgWrapper className="radar">
                            <img src={radarDiagram} alt="radar diagrams"/>
                        </ImgWrapper>
                        <TextWrapper className="right">
                            <h3>The Data</h3>
                            <p>See your results plotted on a radar chart to visually see which areas you are strong in and what areas you can improve on.
                                <br/><br/>You can also compare results against the averages to see how you scale in relation to the masses.
                            </p>
                        </TextWrapper>
                    </LimitWidth>
                </Section>
                <Section>
                    <LimitWidth className="textLeft attributes">
                        <TextWrapper className="left">
                            <h3>The Attributes</h3>
                            <p>DigiDNA was created with 4 base elements:
                                <br/><br/>1 - WAY - The traits that describe how people approach their work, their attitude and motivating forces
                                <br/>2 - KNOW - Knowledge based traits relating to how people understand and learn
                                <br/>3 - HUMAN - The way people interact and collaborate with others, teams and customers
                                <br/>4 - THINK - Traits that describe how people think about the world around them and the tasks they focus on
                                <br/><br/>The four base elements have been split into 10 Attribute Categories that make up the DigiDNA foundations, and it’s these categories which each question relates to.
                            </p>
                        </TextWrapper>
                        <RightSection>
                            <AttributeBox oldQuiz={false} />
                        </RightSection>
                    </LimitWidth>
                </Section>
                <ContactSection>Want to know more, or get an account?<br/><FormButton onClick={() => setShowContactForm(true)}>Get In Touch</FormButton></ContactSection>
            </main>

            <AttributeInfo showModal={moreInfo} setShowModal={setMoreInfo}/>

            <PageTransition isLoading={false}/>
        </>
    )
}

export default Home;

const Hero = styled.header`
    position: relative;
    width: 100vw;
    height: 600px;
    background-image: url(${props => props.bgImg});
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 90px;
    box-sizing: border-box;

    h1, b, h2 {
        color: white;
        margin: 0;
        position: relative;
    }
    h1 {
        font-size: calc(var(--font-large) * 1.8);
        font-weight: 400;
    }

    h2 {
        font-size: var(--font-large);
        font-weight: 400;
    }

    &::before {
        content: "";
        position: absolute;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        height: 100%;
        width: 100%;
        left: 0;
    }

    @media ${device.medium} {
        height: 500px;
    }

    @media ${device.small} {
        height: 400px;
        padding: 0 30px;
        h1 {
            font-size: calc(var(--font-large) * 1.5);
        }

        h2 {
            font-size: calc(var(--font-large) * 0.8);
        }
    }
`;

const Section = styled.section`
    width: 100vw;
    padding: 20px 50px;
    height: auto;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    &:nth-child(odd){
        background-color: var(--primary-gray);
    }

    &.product {
        height: auto;
        background-color: var(--bg-main);
    }

    @media ${device.large} {
        height: auto;

        &.product {
            height: auto;
        }
        .textLeft {
            flex-direction: column-reverse;
        }
        .attributes {
            flex-direction: column;
            margin: 20px 0;
        }
    }

    @media ${device.medium} {
        padding: 0 20px;
    }

    @media ${device.small} {
        height: auto;

        &.product {
            height: auto;
        }
        .textLeft {
            flex-direction: column-reverse;
        }
        .attributes {
            flex-direction: column;
            margin: 20px 0;
        }
    }
`;

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
        color: var(--blue);
        cursor: pointer;
        margin: 10px 0 0;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const LimitWidth = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    padding: 0 50px;

    .left {
        margin-right: 100px;
    }
    .right {
        margin-left: 100px;
    }
    .radar {
        max-width: 380px;
    }

    @media ${device.large} {
        flex-direction: column;
        padding: 0 50px;
        .radar {
            max-width: 240px;
        }
        .left {
            margin-right: 0px;
        }
        .right {
            margin-left: 0px;
        }
        &.attributes {
            flex-direction: column-reverse;
        }
    }

    @media ${device.medium} {
        padding: 0;
    }

    @media ${device.small} {
        flex-direction: column;
        justify-content: center;

        .radar {
            max-width: 480px;
        }
    }
`;

const TextWrapper = styled.div`
    h3 {
        font-size: var(--font-large);
        margin: 0;
        color: var(--accent);
    }
    p {
        font-size: var(--font-small);
        color: var(--text-main);
    }

    @media ${device.small} {
        h3 {
            text-align: center;
        }
        p {
            text-align: center;
        }
    }
`;

const ImgWrapper = styled.div`
    width: 100%;
    max-width: 320px;
    margin: 50px 0;

    img {
        width: 100%;
    }

    @media ${device.large} {
        height: auto;
        max-width: 180px;
        margin: 10px 0;
    }

    @media ${device.small} {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        img {
            width: 50%;
        }
    }
`;

const ContactSection = styled.h4`
    text-align: center;
    font-size: var(--font-medium);
    padding: 25px 0;
    margin: 0;
    background-color: var(--primary-gray);

    button {
        position: static;
        transform: translateX(0);
    }

    @media ${device.small} {
        font-size: calc(var(--font-medium) * 1.25);
    }
`;
