import styled from "styled-components";
import { useAdmin } from "../../context/AdminContext";

const TableGroups = () => {
    const { allUserData, setDisplayUsers, adminData, selectedGroup, setSelectedGroup, setCurrentPage } = useAdmin();

    const changeGroup = (group) => {
        setSelectedGroup(group)
        setCurrentPage(1)

        if (group === "all") {
            setDisplayUsers(allUserData)
        } else {
            setDisplayUsers(adminData.groups.find(g => g._id === group).users.map(userId => allUserData.find(user => user._id === userId)))
        }
    }

    return (
        <GroupsWrapper>
            <OverflowWrapper>
                <GroupBtn className={selectedGroup === "all" ? "active" : ""} onClick={() => changeGroup("all")}>
                    <GroupInfo>
                        <h4>All</h4>
                        <p>{allUserData.length}</p>
                    </GroupInfo>
                </GroupBtn>

                {("groups" in adminData) && (
                    <>
                        {adminData.groups.map((group, index) => {
                            return (
                                <GroupBtn className={selectedGroup === group._id ? "active" : ""} onClick={() => changeGroup(group._id)} key={index}>
                                    <GroupInfo>
                                        <h4>{group.title}</h4>
                                        <p>{group.users.length}</p>
                                    </GroupInfo>
                                </GroupBtn>
                            )
                        })}
                    </>
                )}
                
            </OverflowWrapper>
        </GroupsWrapper>
    )
}

export default TableGroups;

const GroupsWrapper = styled.div`
    margin-bottom: 10px;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100px;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, var(--bg-main) 100%);
        right: 0;
    }
`;
const OverflowWrapper = styled.div`
    display: flex;
    overflow: auto;
    padding-right: 100px;
`;

const GroupBtn = styled.div`
    padding: 10px 0;
    border-bottom: 3px solid var(--gray-light);  
    cursor: pointer;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: background-color 0.2s, border-bottom 0.2s;
    
    &.active {
        border-bottom: 3px solid var(--accent);
    }

    &:not(:last-child) div {
        border-right: 1px solid var(--gray-light);
    }

    &:hover {
        background-color: var(--bg-main-light)
    }
    
`;

const GroupInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;    

    h4 {
        margin: 0 10px 0 0;
        font-weight: 400;
        font-size: var(--font-small);
        white-space: nowrap;
    }
    p {
        background-color: var(--gray-light);
        font-size: 12px;
        padding: 1px 8px;
        border-radius: 3px;
        margin: 0;
    }
`;